export const REPORT_CUSTOMIZATION_COMPONENT_IDS = {
  CHART_TYPE_TOGGLE_DROPDOWN: 'CHART_TYPE_TOGGLE_DROPDOWN',
  TRENDLINE_TOGGLE_DROPDOWN: 'TRENDLINE_TOGGLE_DROPDOWN',
  COLOR_THEME_DROPDOWN: 'COLOR_THEME_DROPDOWN',
  USE_FISCAL_YEAR_CHECKBOX: 'USE_FISCAL_YEAR_CHECKBOX',
  SHOW_TOTALS_CHECKBOX: 'SHOW_TOTALS_CHECKBOX',
  SHOW_TOTALS_COLUMN_CHECKBOX: 'SHOW_TOTALS_COLUMN_CHECKBOX',
  CUMULATIVE_CHECKBOX: 'CUMULATIVE_CHECKBOX',
  HIDE_MARKERS_CHECKBOX: 'HIDE_MARKERS_CHECKBOX',
  SHOW_TODAY_CHECKBOX: 'SHOW_TODAY_CHECKBOX',
  HIDE_DATA_LABELS_CHECKBOX: 'HIDE_DATA_LABELS_CHECKBOX',
  REPORT_DATA_LIMIT_DROPDOWN: 'REPORT_DATA_LIMIT_DROPDOWN',
  COMPARISON_DROPDOWN: 'COMPARISON_DROPDOWN',
  SORT_SELECTOR_DROPDOWN: 'SORT_SELECTOR_DROPDOWN',
  METRICS_EDITOR_DROPDOWN: 'METRICS_EDITOR_DROPDOWN',
  DIMENSION_EDITOR_DROPDOWN: 'DIMENSION_EDITOR_DROPDOWN',
  BREAKDOWN_EDITOR_DROPDOWN: 'BREAKDOWN_EDITOR_DROPDOWN',
  CONDITIONAL_FORMATTING_SETTINGS: 'CONDITIONAL_FORMATTING_SETTINGS',
  ANOMALY_DETECTION_TOGGLE: 'ANOMALY_DETECTION_TOGGLE',
  REPORT_STACKING_DROPDOWN: 'REPORT_STACKING_DROPDOWN',
  DATA_COLUMNS_DROPDOWN: 'DATA_COLUMNS_DROPDOWN',
  UNIFIED_EVENT_DROPDOWN: 'UNIFIED_EVENT_DROPDOWN'
};