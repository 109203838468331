import http from 'hub-http/clients/apiClient';
import quickFetch from 'quick-fetch';
export const NON_RESTRICTED_FLP = 'not_specified';
export const RESTRICTED_FLP = 'hidden';
export const getDataSourceTopProperties = objectTypeId => http.get(`sql-reporting/v1/extended-reportable-options/top-properties/${objectTypeId}`).then(response => {
  return response.portalTopProperties.length > 0 ? response.portalTopProperties : response.defaultTopProperties;
});
const getDataSourcePropertiesForCustomReporting = objectTypeId => http.get(`sql-reporting/v1/extended-reportable-options/properties/${objectTypeId}`);
const getDataSourcePropertiesForBasicReporting = objectTypeId => http.get(`properties/v4/${objectTypeId}?includeFieldLevelPermission=true`);
export const formatCustomReportingPropertiesResponse = response => Object.values(response).map(
// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ propertyDefinition, hiddenFor... Remove this comment to see the full error message
({
  propertyDefinition,
  hiddenForReportBuilder,
  flpRestricted
}) => {
  return Object.assign({}, propertyDefinition.property, {
    hidden: hiddenForReportBuilder,
    accessLevel: flpRestricted ? RESTRICTED_FLP : NON_RESTRICTED_FLP
  });
});
const formatBasicReportingPropertiesResponse = response => response.map(({
  property,
  permission
}) => {
  const {
    accessLevel
  } = permission;
  return Object.assign({}, property, {
    accessLevel
  });
});
export const fetchDataSourceProperties = (objectTypeId, hasCustomReportingAccess) => {
  const getProperties = hasCustomReportingAccess ? getDataSourcePropertiesForCustomReporting : getDataSourcePropertiesForBasicReporting;
  const formatResponse = hasCustomReportingAccess ? formatCustomReportingPropertiesResponse : formatBasicReportingPropertiesResponse;
  const earlyRequestName = `properties-${objectTypeId}`;
  const earlyRequest = quickFetch.getRequestStateByName(earlyRequestName);
  if (earlyRequest) {
    return new Promise((resolve, reject) => {
      earlyRequest.whenFinished(response => {
        resolve(formatResponse(response));
        quickFetch.removeEarlyRequest(earlyRequestName);
      });
      earlyRequest.onError(xhr => {
        if (xhr.status !== 401) {
          // eslint-disable-next-line promise/catch-or-return
          getProperties(objectTypeId).then(formatResponse).then(resolve, reject);
        } else {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(xhr);
        }
        quickFetch.removeEarlyRequest(earlyRequestName);
      });
    });
  } else {
    return getProperties(objectTypeId).then(formatResponse);
  }
};