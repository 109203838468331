/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-misused-promises */

import I18n from 'I18n';
import DSFieldRecord from 'customer-data-filters/filterQueryFormat/DSFieldRecord/DSFieldRecord';
import { DATE_TIME } from 'customer-data-objects/property/PropertyTypes';
import { fromJS } from 'immutable';
import PropertyOptionRecord from 'customer-data-objects/property/PropertyOptionRecord';
import { propertyLabelTranslator } from 'property-translator/propertyTranslator';

// @ts-expect-error Untyped dependency
import getProperties from 'reporting-data/properties';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'repo... Remove this comment to see the full error message
import { hasUnifiedFilterEditorAccess } from 'reporting-enablement/components/reportEditors/UnifiedFilterEditor/utils';
import { isExternalDataType } from 'reporting-data/report/externalReportGetters';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'repo... Remove this comment to see the full error message
import { get as isUnified } from 'reporting-data/retrieve/unified/supported';
// @ts-expect-error Untyped dependency
import { isBlocklistedForFiltering } from 'reporting-data/public/blocklisted-for-filtering';
import { getHidden, getType, getLabel, getName } from '../../filter/utils/schema/propertySchema';
import { isFrameworkDataSource, getReportingDataSourcePropertiesOverrideList } from '../../filter/utils/dataSourceHelpers';
import { GENERIC_DATE_RANGE_PROPERTY_NAME } from '../../filter/utils/dateRangeHelpers';
import { getDashboardFilterOperatorsForType } from '../../filter/utils/getDashboardFilterOperators';
import { isListMembershipProperty, maybeUpdateLegacyListMembershipPropertyName } from '../../filter/utils/listMembershipFilterUtils';
import { getUnifiedDataTypeProperties } from '../../filter/utils/unifiedDataSourceHelpers';
import { convertPropertyToDSFieldRecordWithScriptedField } from '../../filter/utils/filterConversionHelpers';
import { fetchDataSourceProperties } from '../../filter/data/DataSourcePropertiesDAO';
import get from 'transmute/get';
import { createAsyncThunk } from '@reduxjs/toolkit';
const decoratePropertyWithScriptedField = ({
  isScripted,
  property
}) => {
  return isScripted ? property : DSFieldRecord(fromJS(Object.assign({}, property.toJS(), {
    wrappedObject: {
      scripted: true
    }
  })));
};
const getReportingOverrideProperties = async dataSource => {
  try {
    const properties = await getProperties(dataSource);
    const dataSourceProperties = properties.get(dataSource);
    const propertyMap = dataSourceProperties.reduce((next, property) => {
      const maybeUpdatedProperty = maybeUpdateLegacyListMembershipPropertyName(property);
      const dsField = convertPropertyToDSFieldRecordWithScriptedField(maybeUpdatedProperty);
      if (getLabel(dsField) && !getHidden(dsField) && !isBlocklistedForFiltering(dataSource, false, dsField)) {
        next[property.get('name')] = dsField;
      }
      return next;
    }, {});
    return propertyMap;
  } catch (error) {
    console.error(error);
    return {};
  }
};

// similar to this https://git.hubteam.com/HubSpot/abuse-hub-ui/blob/64f5254eb19c741fcb7827a7394935f319f0be0a/abuse-hub/static/js/api/users.ts#L219-L219
export const fetchPropertiesAndReportProperties = async (objectTypeId, hasCustomReportingAccess) => {
  const [propertiesFetchResult, reportingPropertiesFetchResult] = await Promise.allSettled([fetchDataSourceProperties(objectTypeId, hasCustomReportingAccess), getReportingOverrideProperties(objectTypeId)]);
  return {
    properties: propertiesFetchResult.status === 'fulfilled' ? propertiesFetchResult.value : [],
    reportingProperties: reportingPropertiesFetchResult.status === 'fulfilled' ? reportingPropertiesFetchResult.value : {}
  };
};
const getFrameworkDataSourceProperties = async (objectTypeId, hasCustomReportingAccess) => {
  const {
    properties,
    reportingProperties
  } = await fetchPropertiesAndReportProperties(objectTypeId, hasCustomReportingAccess);
  const reportingDataSourcePropertiesOverrideList = getReportingDataSourcePropertiesOverrideList(objectTypeId);
  const dataSourceProperties = properties.reduce((next, property) => {
    const {
      hubspotDefined,
      label,
      name,
      options
    } = property;
    const translatedLabel = hubspotDefined ? propertyLabelTranslator(label) : label;
    next[name] = DSFieldRecord(fromJS(Object.assign({}, property, {
      label: translatedLabel || name,
      options: options ? options.map(PropertyOptionRecord) : options
    })));
    return next;
  }, {});
  const combinedProperties = Object.values(reportingProperties).reduce((next, property) => {
    const {
      name,
      wrappedObject
    } = property;
    const isScripted = get('scripted', wrappedObject || {}) === true;
    const inPuma = name in dataSourceProperties && dataSourceProperties[name] != null;
    if (reportingDataSourcePropertiesOverrideList.includes(name) || !inPuma) {
      next[name] = decoratePropertyWithScriptedField({
        isScripted,
        property
      });
    }
    return next;
  }, dataSourceProperties);
  return combinedProperties;
};
const getFetchPromise = ({
  dataSource,
  scopes,
  hasCustomReportingAccess
}) => {
  if (isFrameworkDataSource(dataSource)) {
    return getFrameworkDataSourceProperties(dataSource, hasCustomReportingAccess);
  }
  if (isUnified(dataSource) && hasUnifiedFilterEditorAccess({
    scopes
  })) {
    return getUnifiedDataTypeProperties(dataSource);
  }
  if (isExternalDataType(dataSource)) {
    return getReportingOverrideProperties(dataSource);
  }
  return Promise.resolve({});
};
export const fetchDataSourcePropertiesAsyncThunk = createAsyncThunk('datasourceProperties/fetch', async ({
  dataSource,
  hasCustomReportingAccess,
  scopes
}) => {
  // For users that do not have access to the unified filter editor (i.e. free/starter portals),
  // as well as for custom data sources (i.e. EMAILS, SOCIAL_POSTS), do not fetch the property information.
  const propertiesPromise = getFetchPromise({
    dataSource,
    hasCustomReportingAccess,
    scopes
  });
  const propertiesResponse = await propertiesPromise;
  if (!isFrameworkDataSource(dataSource)) {
    const GENERIC_DATE_RANGE_PROPERTY = DSFieldRecord({
      name: GENERIC_DATE_RANGE_PROPERTY_NAME,
      label: I18n.text('settingLabels.dateRange.label'),
      type: DATE_TIME
    });
    propertiesResponse[GENERIC_DATE_RANGE_PROPERTY_NAME] = GENERIC_DATE_RANGE_PROPERTY;
  }

  // Filter out hidden properties, properties w/ missing labels, and properties that are blocklisted from filtering.
  return Object.fromEntries(Object.entries(propertiesResponse).filter(([__key, property]) => {
    const propertyName = getName(property);
    const propertyType = getType(property);
    const operatorsForType = getDashboardFilterOperatorsForType(propertyType);

    // Only render "List membership" filter if ungated.
    if (isListMembershipProperty(propertyName)) {
      return true;
    }
    return getLabel(property) && !getHidden(property) && !isBlocklistedForFiltering(dataSource, false, property) && !!operatorsForType;
  }));
});