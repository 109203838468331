import { CURRENCY, NUMBER, PERCENT, DURATION } from 'reporting-data/constants/property-types';
import { getDatasetColumnMaxValue, getDatasetColumnMinValue } from '../utils/datasetUtils';
import { mapInputValueToUsableValue } from '../utils/valueMappingUtils';
import { CONDITIONAL_PALETTES } from '../color/conditionalPalettes';
const hexToRgbDecimal = hexString => {
  const withoutHash = hexString.substring(1);
  const hexComponents = [];
  for (let i = 0; i < withoutHash.length; i += 2) {
    hexComponents.push(withoutHash.substring(i, i + 2));
  }
  const [r, g, b] = hexComponents;
  const toDecimal = v => parseInt(v, 16);
  return {
    r: toDecimal(r),
    g: toDecimal(g),
    b: toDecimal(b)
  };
};
const rgbDecimalToHex = ({
  r,
  g,
  b
}, opacity = 0.5) => {
  const toHex = decimal => {
    const hex = decimal.toString(16);
    const zeros = hex.length === 1 ? '0' : '';
    return `${zeros}${hex}`;
  };
  const opacityValue = Math.round(255 * opacity);
  return `#${toHex(r)}${toHex(g)}${toHex(b)}${toHex(opacityValue)}`;
};
const interpAToB = (aStart, aEnd, bStart, bEnd) => valueFromA => {
  const aRange = aEnd - aStart;
  const bRange = bEnd - bStart;
  const ratio = aRange === 0 ? 0 : bRange / aRange;
  return bStart + (valueFromA - aStart) * ratio;
};
export const getInbetweenColor = (startHex, endHex, ratio, opacity) => {
  const point1RGB = hexToRgbDecimal(startHex);
  const point2RGB = hexToRgbDecimal(endHex);
  const interpR = interpAToB(0, 1, point1RGB.r, point2RGB.r);
  const interpG = interpAToB(0, 1, point1RGB.g, point2RGB.g);
  const interpB = interpAToB(0, 1, point1RGB.b, point2RGB.b);
  const newColorRgb = {
    r: Math.round(interpR(ratio)),
    g: Math.round(interpG(ratio)),
    b: Math.round(interpB(ratio))
  };
  return rgbDecimalToHex(newColorRgb, opacity);
};
const getConditionalPalette = ({
  minValue,
  midValue,
  maxValue,
  minColor,
  midColor,
  maxColor,
  opacity
}) => {
  return value => {
    let rangeMinValue = minValue;
    let rangeMaxValue = maxValue;
    let rangeMinColor = minColor;
    let rangeMaxColor = maxColor;
    if (midValue !== undefined && midColor !== undefined) {
      if (value <= midValue) {
        rangeMaxValue = midValue;
        rangeMaxColor = midColor;
      } else if (value > midValue) {
        rangeMinValue = midValue;
        rangeMinColor = midColor;
      }
    }

    // Returns value between 0 and 1
    const getRatio = (curVal, minVal, maxVal) => {
      return curVal > maxVal ? 1 : curVal < minVal || maxVal === minVal ? 0 : (curVal - minVal) / (maxVal - minVal);
    };
    let ratio = getRatio(value, rangeMinValue, rangeMaxValue);

    /**
     * If there's no user-defined middle value,
     * but there is a middle color defined, we
     * need to adjust the ratio
     * */
    if (midValue === undefined && midColor !== undefined) {
      if (ratio <= 0.5) {
        ratio = getRatio(ratio, 0, 0.5);
        rangeMaxColor = midColor;
      } else if (ratio > 0.5) {
        ratio = getRatio(ratio, 0.5, 1);
        rangeMinColor = midColor;
      }
    }
    return getInbetweenColor(rangeMinColor, rangeMaxColor, ratio, opacity);
  };
};
const CONDITIONAL_FORMATTING_SUPPORTED_PROPERTY_TYPES = [CURRENCY, NUMBER, DURATION, PERCENT];
export const getConditionalFormatter = (dataset, field, formattingOptions) => {
  var _formattingOptions$fo;
  const properyType = dataset.getIn(['properties', field, 'format', 'type']) || '';
  const {
    conditionalFormatting: conditionalFormatterOptions
  } = formattingOptions.format || {};
  if (!CONDITIONAL_FORMATTING_SUPPORTED_PROPERTY_TYPES.includes(properyType) || !(conditionalFormatterOptions !== null && conditionalFormatterOptions !== void 0 && conditionalFormatterOptions.useConditionalFormatting)) {
    return undefined;
  }
  const {
    minColor: userMinColor,
    midColor: userMidColor,
    maxColor: userMaxColor,
    palette
  } = conditionalFormatterOptions;
  let {
    min: userMinValue,
    mid: userMidValue,
    max: userMaxValue
  } = conditionalFormatterOptions;
  userMinValue = mapInputValueToUsableValue(userMinValue, properyType, formattingOptions);
  userMidValue = mapInputValueToUsableValue(userMidValue, properyType, formattingOptions);
  userMaxValue = mapInputValueToUsableValue(userMaxValue, properyType, formattingOptions);
  const maxColumnValue = userMaxValue !== undefined ? userMaxValue : getDatasetColumnMaxValue(dataset, field);
  const minColumnValue = userMinValue !== undefined ? userMinValue : getDatasetColumnMinValue(dataset, field);
  if (maxColumnValue === false || minColumnValue === false) {
    return undefined;
  }
  const {
    colors: {
      minColor: paletteMinColor,
      midColor: paletteMidColor,
      maxColor: paletteMaxColor
    }
  } = palette && CONDITIONAL_PALETTES[palette] || CONDITIONAL_PALETTES.WhiteToOrange;
  const conditionalFormatter = getConditionalPalette({
    minValue: minColumnValue,
    midValue: userMidValue,
    maxValue: maxColumnValue,
    minColor: userMinColor || paletteMinColor,
    midColor: userMidColor || paletteMidColor,
    maxColor: userMaxColor || paletteMaxColor,
    opacity: (_formattingOptions$fo = formattingOptions.format) === null || _formattingOptions$fo === void 0 ? void 0 : _formattingOptions$fo.opacity
  });
  return conditionalFormatter;
};

/**
 *
 * @param paletteId Conditional palette id
 * @param count Minimum value is 2
 * @param opacity Opacity value between 0 and 1
 * @default count 2
 * @returns string[] Array of hex colors
 */
export const getConditionalPaletteSpread = ({
  paletteId,
  count = 2,
  opacity
}) => {
  const palette = CONDITIONAL_PALETTES[paletteId];
  const spread = [];
  if (!palette) {
    return spread;
  }
  const {
    colors: {
      minColor,
      midColor,
      maxColor
    }
  } = palette;
  if (midColor && count < 3) {
    count = 3;
  } else if (count < 2) {
    count = 2;
  }
  const colorFormatter = getConditionalPalette({
    minValue: 0,
    maxValue: count - 1,
    minColor,
    midColor,
    maxColor,
    opacity
  });
  for (let i = 0; i < count; i++) {
    spread.push(colorFormatter(i));
  }
  return spread;
};
export const __TESTABLE__ = {
  getConditionalPalette
};