import { List } from 'immutable';
import memoize from 'transmute/memoize';
import I18n from 'I18n';
import * as http from 'reporting-data/request/http';
import { DatasetInsightOption } from 'reporting-data/tsTypes/datasetInsightTypes';
import { getDatasetInsightOptions } from 'reporting-data/report/reportGetters';
import { setDatasetInsightOptions } from 'reporting-data/report/reportSetters';
import { DATASET_INSIGHT_PARAMS_PROPERTY } from 'reporting-data/datasetInsights';
import { ADVANCED_REPORTING_INSIGHTS_SCOPE } from '../constants/scopes';
import { ADVANCED_INSIGHT_TYPES } from '../constants/datasetInsights';
const VALID_INSIGHTS_API_URL = 'reporting/v2/insights/valid-insights';
export const buildDatasetInsightOptions = (insightOptions, hasAdvancedInsightsAccess) => {
  if (!hasAdvancedInsightsAccess) {
    return insightOptions.map(option => {
      if (option.value in ADVANCED_INSIGHT_TYPES) {
        return Object.assign({}, option, {
          icon: 'locked'
        });
      }
      return option;
    });
  }
  return insightOptions;
};
export const reportHasDatasetInsightOptionType = (report, datasetInsightType) => {
  const datasetInsightOptions = getDatasetInsightOptions(report);
  if (!datasetInsightOptions) {
    return false;
  }
  return datasetInsightOptions.some(insightOption => insightOption.get('insightType') === datasetInsightType);
};
const fetchDatasetInsightTypes = memoize(report => {
  return http.post(VALID_INSIGHTS_API_URL, {
    data: report.toJS()
  });
});
export const enableDatasetInsightType = (report, datasetInsightType) => {
  const datasetInsightOptions = getDatasetInsightOptions(report);
  if (!reportHasDatasetInsightOptionType(report, datasetInsightType)) {
    const insightOptionToAdd = DatasetInsightOption({
      insightType: datasetInsightType
    });
    const updatedDatasetInsightOptions = List(datasetInsightOptions && datasetInsightType === ADVANCED_INSIGHT_TYPES.ANOMALY_DETECTION ? datasetInsightOptions.push(insightOptionToAdd) : [insightOptionToAdd]);
    return setDatasetInsightOptions(report, updatedDatasetInsightOptions);
  }
  return report;
};
export const disableDatasetInsights = (report, datasetInsightType) => {
  // We coerce return type type T because error occurs in downstream code based on the return type of this function. Related to to upgrade to TypeScript 5.1
  // We'd expect to be able to accept just type Report here since it contains insightParams and ConfigReport is of type Report

  const datasetInsightOptions = getDatasetInsightOptions(report);
  if (datasetInsightType && reportHasDatasetInsightOptionType(report, datasetInsightType) && datasetInsightOptions) {
    const updatedDatasetInsightOptions = List(datasetInsightOptions.filter(insightOption => insightOption.get('insightType') !== datasetInsightType));
    return setDatasetInsightOptions(report, updatedDatasetInsightOptions);
  }
  return report.delete(DATASET_INSIGHT_PARAMS_PROPERTY);
};
export const getSelectedDatasetInsightOptionType = report => {
  const datasetInsightOptions = getDatasetInsightOptions(report);
  return datasetInsightOptions && datasetInsightOptions.count() ? datasetInsightOptions.first().get('insightType') : undefined;
};
export const fetchDatasetInsightTypeOptions = async report => {
  const validDatasetInsightTypes = await fetchDatasetInsightTypes(report);
  return validDatasetInsightTypes.map(validDatasetInsightType => {
    return {
      text: I18n.text(`report-customization-components.customization-components.datasetInsightTypes.${validDatasetInsightType}`),
      value: validDatasetInsightType,
      help: I18n.text(`report-customization-components.customization-components.datasetInsightTypesHelpText.${validDatasetInsightType}`)
    };
  }).toArray();
};
export const hasAdvancedInsightsAccess = scopes => scopes && scopes.includes(ADVANCED_REPORTING_INSIGHTS_SCOPE) || false;