import { EDITOR_TYPES } from 'reporting-data/tsTypes/editorTypes';
import { getReportCustomized } from 'reporting-data/report/reportGetters';
import { getBreakdownPropertiesForReport, getDataTypeName, getEditorSchemas, hasFirstDimensionControlSupport } from './salesAnalyticsHelpers';
// @ts-expect-error Untyped dependency
import { deriveGroupingFromReport } from '../constants/sub-app/contextDefaults';
// @ts-expect-error Untyped dependency
import { getMetricsForSubAppDataType } from '../constants/dataTypeMetricOptions';
// @ts-expect-error Untyped dependency
import { getQuickFilterSchemasForReport } from './editorSchemaHelpers';
// @ts-expect-error Untyped dependency
import { getDimensionPropertiesForSchema } from './editorSchemaHelpers';
import { isConfigReport, isCustomWidgetReport, isJourneyReport } from 'reporting-data/tsTypes/reportTypes';
import { SUB_APPS } from 'reporting-data/tsTypes/subAppTypes';
// @ts-expect-error Untyped dependency
import { getSubAppKeyFromReport } from './context-report-sync';
// @ts-expect-error Untyped dependency
import SalesAnalyticsEditor from '../components/SalesAnalyticsEditor';
// @ts-expect-error Untyped dependency
import { checkHasSrvEditorOverrideRemoval } from './access';
import { List } from 'immutable';
const shouldOverrideSrvEditors = (report, gates) => {
  const isSalesAnalyticsDependentReport = isCustomWidgetReport(report) || isJourneyReport(report) || [SUB_APPS.timeInDealStage, SUB_APPS.ticketTimeInPipeline].includes(getSubAppKeyFromReport(report));
  const hasOverrideRemovalGate = checkHasSrvEditorOverrideRemoval(gates);
  return !hasOverrideRemovalGate || isSalesAnalyticsDependentReport;
};
const getEditorSchemasForSavedSalesAnalyticsReport = (report, gates) => {
  const subAppKey = getSubAppKeyFromReport(report);
  if (!subAppKey || getReportCustomized(report) || shouldOverrideSrvEditors(report, gates) || !(isConfigReport(report) || isJourneyReport(report))) {
    return [];
  }
  const grouping = deriveGroupingFromReport(report, subAppKey, gates);
  const existingEditors = getEditorSchemas(report);
  const editorSchemas = existingEditors;
  if (!existingEditors.find(schema => schema.type === EDITOR_TYPES.QUICK_FILTER)) {
    const quickFilterSchemas = getQuickFilterSchemasForReport(report, subAppKey, grouping, gates);
    editorSchemas.push(...quickFilterSchemas);
  }
  if (!existingEditors.find(schema => schema.type === EDITOR_TYPES.DIMENSION)) {
    const dimensionProperties = getDimensionPropertiesForSchema(report, subAppKey, grouping, gates);
    if (hasFirstDimensionControlSupport(report) && dimensionProperties.length > 1) {
      editorSchemas.push({
        type: EDITOR_TYPES.DIMENSION,
        properties: dimensionProperties
      });
    }
  }
  if (!existingEditors.find(schema => schema.type === EDITOR_TYPES.BREAKDOWN)) {
    const breakdownDimensionsToProperties = getBreakdownPropertiesForReport(subAppKey);
    const breakdownProperties = Object.values(breakdownDimensionsToProperties);
    if (breakdownProperties.length > 0) {
      editorSchemas.push({
        type: EDITOR_TYPES.BREAKDOWN,
        properties: breakdownProperties
      });
    }
  }
  if (!existingEditors.find(schema => schema.type === EDITOR_TYPES.METRIC)) {
    const metrics = getMetricsForSubAppDataType(subAppKey, getDataTypeName(report));
    if (metrics.length > 1) {
      editorSchemas.push({
        type: EDITOR_TYPES.METRIC,
        metrics
      });
    }
  }
  return editorSchemas;
};
export const addEditorsToSavedSalesAnalyticsReport = (report, gates) => {
  const salesAnalyticsSchemas = getEditorSchemasForSavedSalesAnalyticsReport(report, gates);
  return salesAnalyticsSchemas.length > 0 ? report.setIn(['editors'], List(salesAnalyticsSchemas)) : report;
};
export const getSalesAnalyticsEditorOverrides = (report, gates) => {
  if (SalesAnalyticsEditor.isUsableForReport(report) && shouldOverrideSrvEditors(report, gates)) {
    return [SalesAnalyticsEditor];
  }
  return undefined;
};