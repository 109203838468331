'use es6';

import { CONVERSATIONS, DEALS, FEEDBACK_SUBMISSIONS, TICKETS } from 'reporting-data/constants/dataTypes';
import { EQ, IN } from 'reporting-data/constants/operators';
import { EDITOR_TYPES } from 'reporting-data/tsTypes/editorTypes';
import CustomWidgets from 'reporting-ui-components/components/customWidgets/constants/widgets';
import { MARKETING_SUB_APPS } from 'reporting-data/tsTypes/subAppTypes';
import { SUB_APPS } from 'reporting-data/tsTypes/subAppTypes';
import { ACCESSIBLE_TEAM_PROPERTY, DIMENSIONS, canGroupByAllAccessibleTeams, getCustomWidgetType, getDataTypeName, getDimensionPropertiesForReport, getDimensionProperty, getEditorSchemas, getStaticDimension, getUserProperty, hasUsersAndTeamsFilteringSupport, isGroupingRequired, isJourneyReport, isQuotaReport, isSinglePipelineReport, isSingleTicketPipelineReport } from './salesAnalyticsHelpers';
export const multiSelectQuickFilterContextFields = {
  hs_inbox_object_id: 'conversationInboxes',
  hs_conversation_channel: 'conversationChannels',
  hs_meeting_outcome: 'meetingOutcomeTypes',
  'meeting.meetingOutcome': 'meetingOutcomeTypes',
  hs_survey_channel: 'surveyChannels',
  hs_ticket_category: 'ticketCategories',
  source_type: 'sourceTypes',
  CONTENT_TYPE_CODE: 'pageType',
  'engagement.type': 'activityTypes',
  deal_change_type: 'changeHistoryTypes',
  raas_historical_change_types: 'changeHistoryTypes',
  pipeline: 'dealPipelines',
  hs_pipeline: 'ticketPipelines',
  hs_pipeline_stage: 'ticketPipelineStages',
  hubspot_team_id: 'teams',
  'engagement.teamId': 'teams',
  hs_enrolled_by_team_ids: 'teams',
  hubspot_owner_id: 'ownerIds',
  'engagement.ownerId': 'ownerIds',
  hs_enrolled_by: 'userIds',
  hs_created_by_user_id: 'userIds',
  hs_created_by: 'userIds',
  hs_asset_type: 'assetType',
  hs_shortmessage_marketing_sms_id: 'sms',
  hs_shortmessage_sending_method: 'sendingMethod',
  hs_marketing_campaign_id: 'campaignId',
  hs_web_interactive_coordinates: 'cta'
};
export const singleSelectQuickFilterContextFields = {
  hs_pipeline: 'ticketPipeline',
  pipeline: 'pipeline',
  pushThresholdMillis: 'pushThreshold',
  filterId: 'analyticsView',
  subdomain: 'domain',
  campaignId: 'campaignId',
  id: 'email',
  teamId: 'team',
  emailType: 'emailType'
};
export const getContextFieldForEditor = editorSchema => {
  const quickFilterContextFields = editorSchema.operator === IN ? multiSelectQuickFilterContextFields : singleSelectQuickFilterContextFields;
  return quickFilterContextFields[editorSchema.property];
};
const getQuickFilterSchema = (property, operator, options = {}) => Object.assign({
  type: EDITOR_TYPES.QUICK_FILTER,
  property,
  operator
}, options);
export const getQuickFilterSchemasForReport = (report, subAppKey, grouping, filteredGates) => {
  const quickFiltersFromTemplate = getEditorSchemas(report).filter(schema => schema.type === EDITOR_TYPES.QUICK_FILTER);
  if (quickFiltersFromTemplate.length > 0) {
    return quickFiltersFromTemplate;
  }
  const schemas = [];
  if (hasUsersAndTeamsFilteringSupport(report)) {
    const userProperty = getUserProperty(report, subAppKey, grouping, filteredGates);
    schemas.push(getQuickFilterSchema(userProperty, IN));
    const teamProperty = getDimensionProperty(report, subAppKey, DIMENSIONS.TEAM, filteredGates);
    schemas.push(getQuickFilterSchema(teamProperty, IN));
  }
  if (subAppKey === SUB_APPS.meetingOutcomes) {
    schemas.push(getQuickFilterSchema('hs_meeting_outcome', IN));
  }
  if (getDataTypeName(report) === DEALS && !isJourneyReport(report)) {
    const operator = isSinglePipelineReport(subAppKey, report) || isQuotaReport(report) ? EQ : IN;
    schemas.push(getQuickFilterSchema('pipeline', operator));
  }
  if (getDataTypeName(report) === TICKETS) {
    schemas.push(getQuickFilterSchema('hs_pipeline', isSingleTicketPipelineReport(subAppKey) ? EQ : IN));
    if (subAppKey === SUB_APPS.supportVolume) {
      schemas.push(getQuickFilterSchema('hs_pipeline_stage', IN));
    }
    schemas.push(getQuickFilterSchema('source_type', IN));
    if (subAppKey !== SUB_APPS.resolutionTime) {
      schemas.push(getQuickFilterSchema('hs_ticket_category', IN));
    }
  }
  if (getDataTypeName(report) === CONVERSATIONS) {
    schemas.push(getQuickFilterSchema('hs_conversation_channel', IN));
    schemas.push(getQuickFilterSchema('hs_inbox_object_id', IN));
  }
  if (getDataTypeName(report) === FEEDBACK_SUBMISSIONS) {
    schemas.push(getQuickFilterSchema('hs_survey_channel', IN));
  }
  if (subAppKey === MARKETING_SUB_APPS.pages) {
    schemas.push(getQuickFilterSchema('CONTENT_TYPE_CODE', IN));
  }
  const customWidgetType = getCustomWidgetType(report);
  if ([CustomWidgets.ENGAGEMENT_STREAM, CustomWidgets.COMPLETED_ACTIVITIES].includes(customWidgetType)) {
    schemas.push(getQuickFilterSchema('engagement.type', IN));
  }
  if (customWidgetType === CustomWidgets.CHANGE_HISTORY_DEAL_LIST) {
    schemas.push(getQuickFilterSchema('deal_change_type', IN));
  }
  if (customWidgetType === CustomWidgets.DEAL_PUSH_ANALYSIS) {
    schemas.push(getQuickFilterSchema('pushThresholdMillis', EQ));
  }
  if (subAppKey === MARKETING_SUB_APPS.attribution) {
    schemas.push(getQuickFilterSchema('hs_asset_type', IN));
  }
  if (subAppKey === MARKETING_SUB_APPS.marketingEmails) {
    schemas.push(getQuickFilterSchema('campaignId', EQ, {
      isClearable: true
    }));
    schemas.push(getQuickFilterSchema('id', EQ, {
      isClearable: true
    }));
    schemas.push(getQuickFilterSchema('teamId', EQ, {
      isClearable: true
    }));
    schemas.push(getQuickFilterSchema('emailType', EQ, {
      isClearable: true
    }));
  }
  if (subAppKey === MARKETING_SUB_APPS.sms) {
    schemas.push(getQuickFilterSchema('hs_shortmessage_marketing_sms_id', IN));
    schemas.push(getQuickFilterSchema('hs_shortmessage_sending_method', IN));
  }
  if (subAppKey === MARKETING_SUB_APPS.cta) {
    schemas.push(getQuickFilterSchema('hs_web_interactive_coordinates', IN));
    schemas.push(getQuickFilterSchema('hs_marketing_campaign_id', IN));
  }
  return schemas;
};
export const getDimensionPropertiesForSchema = (report, subAppKey, grouping, gates) => {
  const reportDimensionsToProperties = getDimensionPropertiesForReport(subAppKey, report, gates);
  const properties = Object.values(reportDimensionsToProperties);
  const canSupportAccessibleTeams = Object.keys(reportDimensionsToProperties).includes(DIMENSIONS.TEAM) && canGroupByAllAccessibleTeams(report);
  if (canSupportAccessibleTeams) {
    properties.push(ACCESSIBLE_TEAM_PROPERTY);
  }
  const staticDimension = getStaticDimension(report, subAppKey, grouping);
  if (staticDimension && !isGroupingRequired(report) && !properties.includes(staticDimension)) {
    properties.unshift(staticDimension);
  }
  return properties;
};