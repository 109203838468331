'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["originalGroupName"];
import getIn from 'transmute/getIn';
import { RequestState } from 'dashboard-lib/public/request/request-state';
import { getFilterQueryFormatOperator } from '../../lib/dashboardFilters/filterConversionHelpers';
import { updateQuickFilterGroups, fetchGeneratedQuickFilterGroups } from '../../data/QuickFilterDAO';
import { fetchDashboard } from '../../actions/DashboardActions';
import { handleErrorMessage } from '../../lib/quickFilters/quickFilterUtils';
import { findDuplicateProperties } from '../../lib/dashboardFilters/groupQuickFilterHelpers';
import { quickFiltersNamespace, getQuickFilterUniqueKey } from './constants';
import { selectQuickFilterGroups, selectQuickFilters, selectCurrentEditingGroup, selectIfCurrentEditingGroupIsValid, selectIfPropertyInCurrentEditingGroup } from './selectors';

// this is for API request actions
export const QUICK_FILTER_REQUEST_ACTION = {
  LOAD: 'load',
  PIN: 'pin',
  UNPIN: 'unpin',
  MODIFY: 'modify',
  REMOVE_FROM_GROUP: 'remove_from_group'
};

// Action types
const SET_DASHBOARD_ID = 'SET_DASHBOARD_ID';
const SET_QUICK_FILTER_GROUPS = 'SET_QUICK_FILTER_GROUPS';
const FETCH_SUCCEEDED = 'FETCH_SUCCEEDED';
const FETCH_FAILED = 'FETCH_FAILED';
const FETCHING = 'FETCHING';
const ADD_QUICK_FILTER_TO_GROUP_DRAFT = 'ADD_QUICK_FILTER_TO_GROUP_DRAFT';
const REMOVE_QUICK_FILTER_FROM_GROUP_DRAFT = 'REMOVE_QUICK_FILTER_FROM_GROUP_DRAFT';
const EDIT_QUICK_FILTER_GROUP = 'EDIT_QUICK_FILTER_GROUP';
const UPDATE_EDIT_QUICK_FILTER_DRAFT_GROUP_NAME_DESC = 'UPDATE_EDIT_QUICK_FILTER_DRAFT_GROUP_NAME_DESC';
const RESET_DRAFT_QUICK_FITLER_GROUP = 'RESET_DRAFT_QUICK_FITLER_GROUP';

// HELPERS
export const getPinnedPropertyActivityMessageOption = entry => {
  const oldValue = entry.get('oldValue');
  const newValue = entry.get('newValue');
  const oldValueSize = oldValue.size;
  const newValueSize = newValue.size;
  if (oldValueSize === newValueSize) {
    return 'reorder';
  }
  return oldValueSize > newValueSize ? 'unpin' : 'pin';
};
export const getAppliedFilterOperator = appliedQuickFilterOperators => (dataSource, property, name) => {
  const key = getQuickFilterUniqueKey({
    dataSource,
    name
  });
  if (!appliedQuickFilterOperators.has(key)) {
    return null;
  }
  return getFilterQueryFormatOperator(appliedQuickFilterOperators.get(key), {
    [property.name]: property
  });
};
const getUngroupedQuickFilters = pinnedPropertyGroups => {
  const ungroupedQuickFilters = pinnedPropertyGroups.filter(quickFilterGroup => {
    // Ungrouped quick filters do not have a grouped filter name
    return !quickFilterGroup.name;
  });
  if (!ungroupedQuickFilters) {
    return [];
  }
  return ungroupedQuickFilters.reduce((acc, ungroupedQuickFilter) => {
    return [...acc, ...ungroupedQuickFilter.properties];
  }, []);
};
const getGroupedQuickFilters = pinnedPropertyGroups => {
  const groupedQuickFilters = pinnedPropertyGroups.filter(quickFilterGroup => {
    // Grouped quick filters have a grouped filter name
    return quickFilterGroup.name;
  });
  return groupedQuickFilters;
};
const prepareQuickFilterGroupsPayload = (quickFilterGroups, quickFilters) => {
  // Format individual quickFilters to the proper shape for the payload.
  const ungroupedQuickFilters = quickFilters.reduce((acc, quickFilter) => {
    return [...acc, {
      properties: [quickFilter]
    }];
  }, []);
  return [...quickFilterGroups, ...ungroupedQuickFilters];
};
const replaceTargetIndexQuickFilterGroup = (quickFilterGroups, targetFilterGroupIndex, filterGroupToUpdate) => {
  return [...quickFilterGroups.slice(0, targetFilterGroupIndex), filterGroupToUpdate, ...quickFilterGroups.slice(targetFilterGroupIndex + 1)];
};
const prepareUpdateTargetIndexQuickFilterGroupPayload = ({
  quickFilterGroups,
  targetGroupName,
  groupDataToUpdate
}) => {
  const groupIndexToUpdate = quickFilterGroups.findIndex(group => group.name === targetGroupName);
  const updatedGroup = Object.assign({}, quickFilterGroups[groupIndexToUpdate], groupDataToUpdate, {
    systemGenerated: false
  });
  return replaceTargetIndexQuickFilterGroup(quickFilterGroups, groupIndexToUpdate, updatedGroup);
};
export const setQuickFilterGroups = pinnedPropertyGroups => dispatch => {
  const ungroupedQuickFilters = getUngroupedQuickFilters(pinnedPropertyGroups);
  const quickFilterGroups = getGroupedQuickFilters(pinnedPropertyGroups);
  dispatch({
    type: SET_QUICK_FILTER_GROUPS,
    payload: {
      quickFilters: ungroupedQuickFilters,
      quickFilterGroups
    }
  });
};
export const onFetchCompletes = dispatch => {
  dispatch({
    type: FETCH_SUCCEEDED
  });
};
export const onFetchError = dispatch => {
  dispatch({
    type: FETCH_FAILED
  });
  return action => {
    handleErrorMessage(action);
  };
};
export const onFetching = dispatch => {
  dispatch({
    type: FETCHING
  });
};
export const resetDraftQuickFilterGroup = () => dispatch => {
  dispatch({
    type: RESET_DRAFT_QUICK_FITLER_GROUP
  });
};
export const editQuickFilterGroup = quickFilterGroup => dispatch => {
  dispatch({
    type: EDIT_QUICK_FILTER_GROUP,
    payload: {
      quickFilterGroup
    }
  });
};
export const onUpdateQuickFilterGroup = dispatch => {
  return ({
    dashboardId,
    newQuickFilterGroupData,
    action = QUICK_FILTER_REQUEST_ACTION.MODIFY
  }) => {
    dispatch({
      type: FETCHING
    });
    updateQuickFilterGroups(dashboardId, newQuickFilterGroupData).then(() => {
      setQuickFilterGroups(newQuickFilterGroupData)(dispatch);
      onFetchCompletes(dispatch);
      resetDraftQuickFilterGroup()(dispatch);

      // This call is necessary to update the Dashboard object stored in the Flux store.
      // Otherwise, the dashboard object stored there would be out of date.
      fetchDashboard(dashboardId);
    }).catch(() => onFetchError(dispatch)(action));
  };
};
export const setDashboardId = dashboardId => dispatch => {
  dispatch({
    type: SET_DASHBOARD_ID,
    payload: {
      dashboardId
    }
  });
};
export const addQuickFilterToDraftGroup = quickFilter => (dispatch, getState) => {
  const state = getState();
  if (selectIfPropertyInCurrentEditingGroup(quickFilter)(state)) {
    return;
  }
  dispatch({
    type: ADD_QUICK_FILTER_TO_GROUP_DRAFT,
    payload: quickFilter
  });
};
export const removeQuickFilterFromDraftGroup = quickFilter => dispatch => {
  dispatch({
    type: REMOVE_QUICK_FILTER_FROM_GROUP_DRAFT,
    payload: quickFilter
  });
};
export const updateDraftQuickFilterGroupNameDesc = formData => dispatch => {
  dispatch({
    type: UPDATE_EDIT_QUICK_FILTER_DRAFT_GROUP_NAME_DESC,
    payload: formData
  });
};
export const pinUngroupedQuickFilter = filterToPin => (dispatch, getState) => {
  const state = getState();
  const quickFilterGroups = selectQuickFilterGroups(state);
  const ungroupedQuickFilter = selectQuickFilters(state);
  const dashboardId = getIn([quickFiltersNamespace, 'dashboardId'], state);

  // Add the filter to pin to
  ungroupedQuickFilter.push(Object.assign({}, filterToPin));
  const newQuickFilterGroupData = prepareQuickFilterGroupsPayload(quickFilterGroups, ungroupedQuickFilter);

  // Add validation does not exceed 5 (ungrouped + grouped)
  // add validation to check if property is existing in either ungrouped or grouped

  onUpdateQuickFilterGroup(dispatch)({
    dashboardId,
    newQuickFilterGroupData,
    action: QUICK_FILTER_REQUEST_ACTION.PIN
  });
};
export const unpinUngroupedQuickFilter = filterToUnpin => (dispatch, getState) => {
  const state = getState();
  const quickFilters = getIn([quickFiltersNamespace, 'quickFilters'], state) || [];
  const quickFilterGroup = selectQuickFilterGroups(state) || [];
  const dashboardId = getIn([quickFiltersNamespace, 'dashboardId'], state);
  const {
    dataSourceId,
    name
  } = filterToUnpin;
  const updatedQuickFilters = quickFilters.filter(filter => !(filter.dataSourceId === dataSourceId && filter.name === name));
  const newQuickFilterGroupData = prepareQuickFilterGroupsPayload(quickFilterGroup, updatedQuickFilters);
  onUpdateQuickFilterGroup(dispatch)({
    dashboardId,
    newQuickFilterGroupData,
    action: QUICK_FILTER_REQUEST_ACTION.UNPIN
  });
};
export const pinCurrentEditingGroup = () => (dispatch, getState) => {
  const state = getState();
  if (!selectIfCurrentEditingGroupIsValid(state)) {
    return;
  }
  const dashboardId = getIn([quickFiltersNamespace, 'dashboardId'], state);
  const quickFilterGroups = selectQuickFilterGroups(state);
  const currentEditingGroup = selectCurrentEditingGroup(state);
  const {
      originalGroupName
    } = currentEditingGroup,
    filterGroupToUpdate = _objectWithoutPropertiesLoose(currentEditingGroup, _excluded);
  let updatedQuickFilterGroupWithNewGroup = [];
  if (currentEditingGroup.originalGroupName === '') {
    /* add new group */
    if (quickFilterGroups.some(quickFilterGroup => quickFilterGroup.name === currentEditingGroup.name)) {
      return;
    }
    updatedQuickFilterGroupWithNewGroup = [...quickFilterGroups, filterGroupToUpdate];
  } else {
    const targetFilterGroupIndex = quickFilterGroups.findIndex(quickFilterGroup => quickFilterGroup.name === originalGroupName);
    if (targetFilterGroupIndex === -1) {
      return; // TODO: add error message;
    }
    if (quickFilterGroups.some((quickFilterGroup, index) => quickFilterGroup.name === currentEditingGroup.name && index !== targetFilterGroupIndex)) {
      return;
    }
    updatedQuickFilterGroupWithNewGroup = replaceTargetIndexQuickFilterGroup(quickFilterGroups, targetFilterGroupIndex, filterGroupToUpdate);
  }
  const ungroupedQuickFilters = selectQuickFilters(state);
  const newQuickFilterGroupData = prepareQuickFilterGroupsPayload(updatedQuickFilterGroupWithNewGroup, ungroupedQuickFilters);
  onUpdateQuickFilterGroup(dispatch)({
    dashboardId,
    newQuickFilterGroupData
  });
};
export const unpinQuickFilterGroup = quickFilterGroup => (dispatch, getState) => {
  const state = getState();
  const quickFilters = getIn([quickFiltersNamespace, 'quickFilters'], state) || [];
  const quickFilterGroups = selectQuickFilterGroups(state) || [];
  const dashboardId = getIn([quickFiltersNamespace, 'dashboardId'], state);
  const {
    name
  } = quickFilterGroup;
  const updatedQuickFilterGroups = quickFilterGroups.filter(group => group.name !== name);
  const newQuickFilterGroupData = prepareQuickFilterGroupsPayload(updatedQuickFilterGroups, quickFilters);
  onUpdateQuickFilterGroup(dispatch)({
    dashboardId,
    newQuickFilterGroupData,
    action: QUICK_FILTER_REQUEST_ACTION.UNPIN
  });
};
export const unpinAllQuickFilterGroups = () => (dispatch, getState) => {
  const state = getState();
  const dashboardId = getIn([quickFiltersNamespace, 'dashboardId'], state);
  onUpdateQuickFilterGroup(dispatch)({
    dashboardId,
    newQuickFilterGroupData: [],
    action: QUICK_FILTER_REQUEST_ACTION.UNPIN
  });
};
export const removePropertyFromGroup = (quickFilterGroup, propertyToRemove) => (dispatch, getState) => {
  const state = getState();
  const dashboardId = getIn([quickFiltersNamespace, 'dashboardId'], state);
  const quickFilters = getIn([quickFiltersNamespace, 'quickFilters'], state) || [];
  const quickFilterGroups = selectQuickFilterGroups(state) || [];
  const {
    name: groupName,
    properties
  } = quickFilterGroup;
  const updatedProperties = properties.filter(property => {
    return !(property.name === propertyToRemove.name && property.dataSourceId === propertyToRemove.dataSource);
  });

  // If there are no remaining properties in the quick filter group, then remove it
  // from the list of quick filter groups.
  // Otherwise, update the modified quick filter group.
  const updatedQuickFilterGroups = updatedProperties.length === 0 ? quickFilterGroups.filter(group => group.name !== groupName) : prepareUpdateTargetIndexQuickFilterGroupPayload({
    quickFilterGroups,
    targetGroupName: groupName,
    groupDataToUpdate: {
      properties: updatedProperties
    }
  });
  const newQuickFilterGroupData = prepareQuickFilterGroupsPayload(updatedQuickFilterGroups, quickFilters);
  onUpdateQuickFilterGroup(dispatch)({
    dashboardId,
    newQuickFilterGroupData,
    action: QUICK_FILTER_REQUEST_ACTION.REMOVE_FROM_GROUP
  });
};
export const pinGeneratedQuickFilterGroups = generatedQuickFilterGroups => (dispatch, getState) => {
  const state = getState();
  const dashboardId = getIn([quickFiltersNamespace, 'dashboardId'], state);
  const quickFilters = selectQuickFilters(state) || [];
  const quickFilterGroups = selectQuickFilterGroups(state) || [];
  const updatedQuickFilterGroups = [...generatedQuickFilterGroups, ...quickFilterGroups];
  const newQuickFilterGroupData = prepareQuickFilterGroupsPayload(updatedQuickFilterGroups, quickFilters);
  onUpdateQuickFilterGroup(dispatch)({
    dashboardId,
    newQuickFilterGroupData,
    action: QUICK_FILTER_REQUEST_ACTION.PIN
  });
};
export const generateQuickFilterGroups = ({
  dashboardId,
  quickFilterGroupGenerationOptionsList
}) => (dispatch, getState) => {
  const state = getState();
  const quickFilters = selectQuickFilters(state) || [];
  const quickFilterGroups = selectQuickFilterGroups(state) || [];
  return fetchGeneratedQuickFilterGroups(dashboardId, quickFilterGroupGenerationOptionsList).then(generatedQuickFilterGroups => {
    const duplicateProperties = findDuplicateProperties(generatedQuickFilterGroups, quickFilterGroups, quickFilters);
    const duplicatesExists = duplicateProperties.length > 0;
    if (!duplicatesExists) {
      dispatch(pinGeneratedQuickFilterGroups(generatedQuickFilterGroups));
    }
    return {
      duplicatesExists,
      generatedQuickFilterGroups
    };
  }).catch(err => {
    console.error(err);
    return err;
  });
};
export const updateQuickFiltersWithConflictsResolved = ({
  updatedQuickFilterGroups,
  updatedQuickFilters
}) => (dispatch, getState) => {
  const state = getState();
  const dashboardId = getIn([quickFiltersNamespace, 'dashboardId'], state);
  const newQuickFilterGroupData = prepareQuickFilterGroupsPayload(updatedQuickFilterGroups, updatedQuickFilters);
  onUpdateQuickFilterGroup(dispatch)({
    dashboardId,
    newQuickFilterGroupData,
    action: QUICK_FILTER_REQUEST_ACTION.PIN
  });
};

// Reducer
export const quickFilterReducer = (state = {
  quickFilters: [],
  fetchQuickFilterStatus: RequestState.UNINITIALIZED,
  quickFilterGroups: [],
  currentEditingGroup: {
    properties: [],
    name: '',
    description: '',
    originalGroupName: ''
  }
}, action) => {
  switch (action.type) {
    case SET_DASHBOARD_ID:
      {
        // KEEP a dashboard id reference in the store.
        const {
          dashboardId
        } = action.payload;
        return Object.assign({}, state, {
          dashboardId
        });
      }
    case SET_QUICK_FILTER_GROUPS:
      {
        const {
          quickFilters,
          quickFilterGroups
        } = action.payload;
        return Object.assign({}, state, {
          quickFilters,
          quickFilterGroups,
          fetchQuickFilterStatus: RequestState.SUCCEEDED
        });
      }
    case FETCHING:
      {
        return Object.assign({}, state, {
          fetchQuickFilterStatus: RequestState.PENDING
        });
      }
    case FETCH_SUCCEEDED:
      {
        return Object.assign({}, state, {
          fetchQuickFilterStatus: RequestState.SUCCEEDED
        });
      }
    case FETCH_FAILED:
      {
        return Object.assign({}, state, {
          fetchQuickFilterStatus: RequestState.FAILURE
        });
      }
    case RESET_DRAFT_QUICK_FITLER_GROUP:
      {
        return Object.assign({}, state, {
          currentEditingGroup: {
            properties: [],
            name: '',
            description: '',
            originalGroupName: ''
          }
        });
      }
    case EDIT_QUICK_FILTER_GROUP:
      {
        const {
          quickFilterGroup
        } = action.payload;
        return Object.assign({}, state, {
          currentEditingGroup: Object.assign({}, quickFilterGroup, {
            originalGroupName: quickFilterGroup.name
          })
        });
      }
    case ADD_QUICK_FILTER_TO_GROUP_DRAFT:
      {
        const {
          quickFilter
        } = action.payload;
        return Object.assign({}, state, {
          currentEditingGroup: Object.assign({}, state.currentEditingGroup, {
            properties: [...state.currentEditingGroup.properties, quickFilter],
            systemGenerated: false
          })
        });
      }
    case REMOVE_QUICK_FILTER_FROM_GROUP_DRAFT:
      {
        const {
          quickFilter
        } = action.payload;
        return Object.assign({}, state, {
          currentEditingGroup: Object.assign({}, state.currentEditingGroup, {
            properties: state.currentEditingGroup.properties.filter(property => {
              return !(property.dataSourceId === quickFilter.dataSourceId && property.name === quickFilter.name);
            }),
            systemGenerated: false
          })
        });
      }
    case UPDATE_EDIT_QUICK_FILTER_DRAFT_GROUP_NAME_DESC:
      {
        const formData = action.payload;
        return Object.assign({}, state, {
          currentEditingGroup: Object.assign({}, state.currentEditingGroup, formData, {
            systemGenerated: false
          })
        });
      }
    default:
      return state;
  }
};