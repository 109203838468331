'use es6';

import { Record, List, Map as ImmutableMap } from 'immutable';
import { DAY } from 'reporting-data/constants/frequency';
import { RANGE_TYPES } from 'reporting-data/constants/dateRangeTypes';
import { FUNNEL } from 'reporting-data/constants/chartTypes';
import { THEME } from 'reporting-ui-components/constants/themes';
import { DIMENSIONS, getDateRange, PUSH_THRESHOLD_MILLS, TEAM_GROUPING_TYPE } from '../utils/salesAnalyticsHelpers';
import { SUPPORTED_SALES_ENGAGEMENT_TYPES } from '../utils/engagementHelpers';
const Context = Record({
  activityTypes: List(SUPPORTED_SALES_ENGAGEMENT_TYPES),
  assetType: List(),
  analyticsView: null,
  breakdown: null,
  campaignId: null,
  changeHistoryTypes: List(),
  chartMetrics: List(),
  chartType: FUNNEL,
  colorTheme: THEME.MULTI,
  conversationChannels: List(),
  conversationInboxes: List(),
  customFilters: List(),
  cta: List(),
  relationalCustomFilters: ImmutableMap(),
  dateRange: ImmutableMap(),
  dealPipelines: List(),
  dealStages: List(),
  domain: null,
  drilldowns: List(),
  email: null,
  event: null,
  emailType: null,
  frequency: DAY,
  compare: null,
  grouping: DIMENSIONS.OWNER,
  intraperiod: false,
  journeyDateRange: ImmutableMap(),
  showDropoff: false,
  contactObjectFiltering: ImmutableMap(),
  includeAnonymousVisitors: false,
  leadStages: List(),
  limitTable: 10,
  meetingOutcomeTypes: List(),
  metric: null,
  tableMetrics: List(),
  optionalDealStages: List(),
  optionalLeadStages: List(),
  ownerIds: List(),
  pageType: List(),
  pipeline: null,
  pushThreshold: String(PUSH_THRESHOLD_MILLS['DAYS_30']),
  secondaryDateRange: ImmutableMap(),
  secondaryMetric: null,
  staticGoal: null,
  sms: List(),
  sendingMethod: List(),
  tab: null,
  team: null,
  teams: List(),
  teamGroupingType: TEAM_GROUPING_TYPE.PRIMARY_TEAM,
  sourceTypes: List(),
  surveyChannels: List(),
  ticketCategories: List(),
  ticketPipeline: '',
  ticketPipelines: List(),
  ticketPipelineStages: List(),
  userIds: List(),
  filteredBreakdowns: List(),
  question: null,
  savedContext: null
}, 'Context');
export default Context;
export const constructContext = overrideProps => Context(Object.assign({
  dateRange: getDateRange(RANGE_TYPES.LAST_MONTH),
  secondaryDateRange: getDateRange(RANGE_TYPES.LAST_MONTH)
}, overrideProps));
export const getContextDiff = context => {
  const defaultContext = constructContext();
  return ImmutableMap(context).filterNot((value, key) => value === defaultContext.get(key) || key === 'savedContext');
};
const JSON_LIST_FIELDS = ['customFilters', 'drilldowns'];
export const ALL_PROPS = Array.from(Context().keys());
export const LIST_PROPS = Array.from(Context().entries()).filter(([key, val]) => List.isList(val) && !JSON_LIST_FIELDS.includes(key)).map(([key]) => key);
export const MAP_PROPS = Array.from(Context().entries()).filter(([, val]) => ImmutableMap.isMap(val)).map(([key]) => key);
export const JSON_PROPS = MAP_PROPS.concat(JSON_LIST_FIELDS);