module.exports = {
  "conditionalFormattingInteraction": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "activate",
          "selectColumn",
          "removeColumn",
          "updateMin",
          "updateMax",
          "updateMidpoint",
          "addMid",
          "removeMid",
          "updateColorPalette",
          "openHelpArticle",
          "updateDuration",
          "reapplyToColumn"
        ],
        "isOptional": false
      },
      "columnType": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "string",
        "isOptional": true
      },
      "color": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "report-customization-components",
    "version": "1"
  }
};