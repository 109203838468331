import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { usePlatformDashboardSelector, usePlatformDashboardDispatch } from 'platform-dashboard-ui/store';
import { selectHasSavedFiltersAccess, selectHasBackendAppliedFiltersAccess
// @ts-expect-error migrate upstream
} from 'dashboard-lib/public/userInfo/gates';
// @ts-expect-error migrate upstream
import { selectHasCustomReportingAcccess } from 'dashboard-lib/public/userInfo/scopes';
// @ts-expect-error migrate upstream
import { selectAllScopes } from 'dashboard-lib/public/userInfo';
import { getDashboardReportDataSources } from '../lib/dashboardFilters/dataSourceHelpers';
import { selectDataSourcesProperties, selectDataSourcesPropertiesStatus, fetchDataSourcesProperties } from 'platform-dashboard-ui/ducks/dataSourcePropertiesSlice';
import { RequestState } from 'platform-dashboard-ui/filter/requests/request-state';
import { selectDataSourcesMetadataStatus, fetchDataSourcesMetadata, selectDataSourcesMetadataWithUniqueLabels } from 'platform-dashboard-ui/ducks/dataSourceMetadataSlice';
// @ts-expect-error migrate upstream
import { getId as getDashboardId } from 'dashboard-lib/public/dashboard/dashboard-schema';
import { dashboardReportsActions } from '../ducks/dashboard-reports';
import { initializeDashboardFilters } from '../ducks/applied-dashboard-filters';
import { getSavedFilterSetIdQueryParam } from '../lib/dashboardFilters/dashboard-filter-query-params-util';
import { fetchSavedFilterById, getFilterSetById, transformSavedFilterToDashboardFilter } from '../lib/savedDashboardFilters';
import { buildFailedAsyncData, buildStartedAsyncData, buildSucceededAsyncData, buildUnitializedAsyncData, isFailed, isSucceeded, isUninitialized } from 'reporting-data/asyncData/AsyncData';
import { DYNAMIC_DATE_RANGE_PROPERTY_NAME } from '../lib/dashboardFilters/types';
import get from 'transmute/get';
import { List } from 'immutable';
import { QUICK_FILTER_TEMPLATES, isDateRangeGeneratedQuickFilterGroup } from '../lib/dashboardFilters/groupQuickFilterHelpers';
// @ts-expect-error migrate upstream
import { fetchGeneratedQuickFilterGroups } from '../data/QuickFilterDAO';
const useShouldInitializeSavedDashboardFilters = () => {
  const hasSavedFiltersAccess = useSelector(selectHasSavedFiltersAccess);
  const history = useHistory();
  const filterSetIdOverride = getSavedFilterSetIdQueryParam({
    history
  });
  return hasSavedFiltersAccess || !!filterSetIdOverride;
};

// TODO split out into another data fetching hook once property metadata store API is ready
export const useFetchDataSourcesForDashboard = dashboard => {
  const hasCustomReportingAccess = useSelector(selectHasCustomReportingAcccess);
  const scopes = useSelector(selectAllScopes);
  const platformDispatch = usePlatformDashboardDispatch();
  const dataSourceIds = (_dashboard$get => {
    const reportDataSourceIds = getDashboardReportDataSources(dashboard);
    const apiSavedFilterSets = dashboard.get('filters').toJS();
    const apiDashboardFilters = apiSavedFilterSets.map(apiFilterSet => apiFilterSet.filters).flat().map(filter => filter.dataSourceId).filter(dataSourceId => dataSourceId != null);
    const quickFilterGroupSources = (((_dashboard$get = dashboard.get('filterGroups')) === null || _dashboard$get === void 0 ? void 0 : _dashboard$get.toJS()) || []).map(g => g.properties.map(p => p.dataSourceId)).flat();
    const savedFilterSetDataSources = [...new Set(apiDashboardFilters)];
    return [...new Set(savedFilterSetDataSources.concat(reportDataSourceIds).concat(quickFilterGroupSources))];
  })();
  const dataSourcesProperties = usePlatformDashboardSelector(selectDataSourcesProperties(dataSourceIds));
  const dataSourcesPropertiesStatus = usePlatformDashboardSelector(selectDataSourcesPropertiesStatus(dataSourceIds));
  const dataSourcesMetadata = usePlatformDashboardSelector(selectDataSourcesMetadataWithUniqueLabels(dataSourceIds));
  const dataSourcesMetadataStatus = usePlatformDashboardSelector(selectDataSourcesMetadataStatus(dataSourceIds));
  const areDataSourcesPropertiesLoaded = dataSourcesPropertiesStatus === RequestState.SUCCEEDED || dataSourcesPropertiesStatus === RequestState.FAILED;
  const areDataSourcesMetadataLoaded = dataSourcesMetadataStatus === RequestState.SUCCEEDED || dataSourcesMetadataStatus === RequestState.FAILED;

  // load data sources properties
  useEffect(() => {
    if (dataSourcesPropertiesStatus === RequestState.UNINITIALIZED) {
      platformDispatch(fetchDataSourcesProperties(dataSourceIds, hasCustomReportingAccess, scopes));
    }
  }, [dataSourceIds, dataSourcesPropertiesStatus, hasCustomReportingAccess, platformDispatch, scopes]);

  // load data sources metadata
  useEffect(() => {
    if (dataSourcesMetadataStatus === RequestState.UNINITIALIZED) {
      platformDispatch(fetchDataSourcesMetadata(dataSourceIds));
    }
  }, [dataSourceIds, dataSourcesMetadataStatus, platformDispatch]);
  return {
    isLoaded: areDataSourcesMetadataLoaded && areDataSourcesPropertiesLoaded,
    dataSourcesMetadata,
    dataSourcesProperties
  };
};
const getDynamicDateRangeQuickFilterFromDashboard = dashboard => {
  const rawQuickFilterGroups = get('filterGroups', dashboard) || [];
  const quickFilterGroups = List(rawQuickFilterGroups).toJS();
  const maybeDynamicDateRangeQuickFilterGroup = quickFilterGroups.find(isDateRangeGeneratedQuickFilterGroup);
  return maybeDynamicDateRangeQuickFilterGroup;
};
export const useInitializeFilters = (dashboard, isImprovedDBFiltersOptedIn, shouldInitializeDashboardFilters) => {
  const hasBackendAppliedFiltersAccess = useSelector(selectHasBackendAppliedFiltersAccess);
  const dashboardId = getDashboardId(dashboard);
  const history = useHistory();
  /** dashboard id of filters to initialize */
  const [initializedDashboardId, setInitializedDashboardId] = useState();
  const dispatch = useDispatch();
  const [fetchEmailOnlyFilterSetAsyncData, setFetchEmailOnlyFilterSetAsyncData] = useState(buildUnitializedAsyncData());
  const hasInitializedFilters = initializedDashboardId === dashboardId;
  const dynamicDateRangeFilterGroup = getDynamicDateRangeQuickFilterFromDashboard(dashboard);
  const [dynamicDateRangePropertiesAsyncState, setDynamicDateRangePropertiesAsyncState] = useState(dynamicDateRangeFilterGroup ? buildSucceededAsyncData(dynamicDateRangeFilterGroup.properties) : buildUnitializedAsyncData());
  const shouldIntialize = isImprovedDBFiltersOptedIn && shouldInitializeDashboardFilters && !hasInitializedFilters;
  const shouldInitializeSavedFilters = useShouldInitializeSavedDashboardFilters();
  const shouldIntializeBEFilters = isImprovedDBFiltersOptedIn && shouldInitializeDashboardFilters && hasInitializedFilters;
  const shouldUseBEAppliedFilters = hasBackendAppliedFiltersAccess && shouldIntializeBEFilters;
  useEffect(() => {
    if (shouldUseBEAppliedFilters) {
      dispatch(dashboardReportsActions.initializeDashboardReports({
        dashboard
      }));
    }
    //  prevent running this whenever dashboard object changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, shouldUseBEAppliedFilters]);
  const {
    isLoaded: areDataSourceDependenciesLoaded,
    dataSourcesMetadata,
    dataSourcesProperties
  } = useFetchDataSourcesForDashboard(dashboard);
  useEffect(() => {
    if (initializedDashboardId && dashboardId !== initializedDashboardId) {
      setFetchEmailOnlyFilterSetAsyncData(buildUnitializedAsyncData());
      setInitializedDashboardId(undefined);
    }
  }, [dashboardId, initializedDashboardId]);
  useEffect(() => {
    if (!shouldIntialize) {
      return;
    }
    if (shouldInitializeSavedFilters) {
      if (areDataSourceDependenciesLoaded) {
        const apiSavedFilterSets = dashboard.get('filters').toJS();
        const emailOnlyFilterSetContainsDynamicDateRangeProperties = (() => {
          if (isSucceeded(fetchEmailOnlyFilterSetAsyncData)) {
            return fetchEmailOnlyFilterSetAsyncData.data.filters.some(filter => filter.name === DYNAMIC_DATE_RANGE_PROPERTY_NAME);
          }
          return false;
        })();
        const shouldHydrateDynamicDateRangeProperties = apiSavedFilterSets.some(filterSet => filterSet.filters.some(filter => filter.name === DYNAMIC_DATE_RANGE_PROPERTY_NAME)) || emailOnlyFilterSetContainsDynamicDateRangeProperties;
        const fetchDynamicDateRangeProperties = () => {
          setDynamicDateRangePropertiesAsyncState(buildStartedAsyncData());
          fetchGeneratedQuickFilterGroups(dashboardId, [QUICK_FILTER_TEMPLATES.DATE_RANGE]).then(response => {
            const dateRangeQuickFilterGroup = response.find(isDateRangeGeneratedQuickFilterGroup);
            const nextDateRangeProperties = dateRangeQuickFilterGroup ? dateRangeQuickFilterGroup.properties : [];
            setDynamicDateRangePropertiesAsyncState(buildSucceededAsyncData(nextDateRangeProperties));
          }).catch(error => {
            console.error(error);
            setDynamicDateRangePropertiesAsyncState(buildSucceededAsyncData([]));
          });
        };
        if (shouldHydrateDynamicDateRangeProperties) {
          if (isUninitialized(dynamicDateRangePropertiesAsyncState)) {
            fetchDynamicDateRangeProperties();
            return;
          }
          if (!isSucceeded(dynamicDateRangePropertiesAsyncState)) {
            return;
          }
        }
        const transformedFilterSets = apiSavedFilterSets.map(filterSet => Object.assign({}, filterSet, {
          filters: transformSavedFilterToDashboardFilter(filterSet.filters, dataSourcesProperties, dataSourcesMetadata, isSucceeded(dynamicDateRangePropertiesAsyncState) ? dynamicDateRangePropertiesAsyncState.data : [])
        }));
        const filterSetIdOverride = getSavedFilterSetIdQueryParam({
          history
        });
        const shouldLoadOverriddenFilterSet = filterSetIdOverride && !getFilterSetById(transformedFilterSets, filterSetIdOverride);
        if (shouldLoadOverriddenFilterSet) {
          // initialize dashboard filters w/ email only filter override
          if (isSucceeded(fetchEmailOnlyFilterSetAsyncData)) {
            if (shouldHydrateDynamicDateRangeProperties && !isSucceeded(dynamicDateRangePropertiesAsyncState)) {
              return;
            }
            const emailOnlyFilterSet = Object.assign({}, fetchEmailOnlyFilterSetAsyncData.data, {
              filters: transformSavedFilterToDashboardFilter(fetchEmailOnlyFilterSetAsyncData.data.filters, dataSourcesProperties, dataSourcesMetadata, isSucceeded(dynamicDateRangePropertiesAsyncState) ? dynamicDateRangePropertiesAsyncState.data : [])
            });
            dispatch(initializeDashboardFilters({
              dashboardId,
              history,
              filterSetId: filterSetIdOverride,
              filterSets: [emailOnlyFilterSet, ...transformedFilterSets]
            }));
            setInitializedDashboardId(dashboardId);
          }

          // initialize dashboard filters w/o filter override
          if (isFailed(fetchEmailOnlyFilterSetAsyncData)) {
            dispatch(initializeDashboardFilters({
              dashboardId,
              history,
              filterSets: transformedFilterSets
            }));
            setInitializedDashboardId(dashboardId);
          }

          // Load email only filter set by id
          if (isUninitialized(fetchEmailOnlyFilterSetAsyncData)) {
            setFetchEmailOnlyFilterSetAsyncData(buildStartedAsyncData());
            fetchSavedFilterById(filterSetIdOverride).then(response => {
              setFetchEmailOnlyFilterSetAsyncData(buildSucceededAsyncData(response));
            }).catch(error => {
              setFetchEmailOnlyFilterSetAsyncData(buildFailedAsyncData(error));
            });
          }
        } else {
          dispatch(initializeDashboardFilters({
            dashboardId,
            history,
            filterSetId: filterSetIdOverride,
            filterSets: transformedFilterSets
          }));
          setInitializedDashboardId(dashboardId);
        }
      }
    } else {
      dispatch(initializeDashboardFilters({
        dashboardId,
        history
      }));
      setInitializedDashboardId(dashboardId);
    }
  }, [areDataSourceDependenciesLoaded, dashboard, dashboardId, dataSourcesMetadata, dataSourcesProperties, dispatch, dynamicDateRangePropertiesAsyncState, fetchEmailOnlyFilterSetAsyncData, history, shouldInitializeSavedFilters, shouldIntialize]);
  return {
    areDataSourceDependenciesLoaded
  };
};