'use es6';

import I18n from 'I18n';
import { fromJS } from 'immutable';
import prefix from '../../lib/prefix';
import { Promise } from '../../lib/promise';
import { load } from '../../dataTypeDefinitions';
import createPropertiesGetterFromGroups from '../createPropertiesGetterFromGroups';
import getSourcesGroups from '../partial/contacts-sources';
import { SCP_CONTENT_TYPE } from '../constants/analyticsMetadataProperties';

/**
 * Shared people spec
 *
 * @constant {object}
 * @returns {string} Normalized translation key
 * @private
 */
const peopleSpec = {
  deleted: 'boolean',
  email: 'string',
  first_conversion_date: 'datetime',
  first_conversion_event_name: 'string',
  firstname: 'string',
  guids: 'string',
  hs_analytics_first_timestamp: 'datetime',
  hs_analytics_source_data_1: 'string',
  hs_analytics_source_data_2: 'string',
  hs_analytics_source: () => getSourcesGroups().toJS(),
  isContact: 'boolean',
  lastname: 'string',
  lifecyclestage: 'enumeration',
  people: 'enumeration',
  recent_conversion_date: 'datetime',
  recent_conversion_event_name: 'string',
  vid: 'enumeration',
  hs_marketable_status: 'boolean',
  hs_marketable_reason_id: 'enumeration',
  hs_marketable_reason_type: 'enumeration'
};

/**
 * Create translation key from data type
 *
 * @param {string} dataType Data type
 * @returns {string} Normalized translation key
 * @private
 */
export const createUnifiedTranslationKey = dataType => dataType.toLowerCase().replace(/[^a-z0-9]/g, '-').replace(/^analytics-/, '');

/**
 * Get property type from spec
 *
 * @param {(string|object)} type Type or options
 * @returns {string} Property type
 * @private
 */
const getPropertyType = type => typeof type === 'string' ? type : 'enumeration';

/**
 * Create options from options definition
 *
 * @param {object} options Options
 * @returns {object[]} Formatted options
 * @private
 */
const createOptions = options => {
  return Object.keys(options).reduce((memo, key) => [...memo, {
    value: key,
    label: options[key]
  }], []);
};

/**
 * Used to inverse deltas on Data Well reports
 */
const metricsToInverse = ['bounces', 'pageBounces', 'bounceRate', 'pageBounceRate', 'exits', 'exitsPerPageview', 'unsubscribedratio'];

/**
 * Used when we want to hide specific properties from the customer
 * For example, SCP content type is a property required for the case-study content type
 * but to a customer they should only see a CONTENT_TYPE_CODE filter and not the additional SCP one
 */
const hiddenFilterProperties = [SCP_CONTENT_TYPE];
const getAdditionalPropertyInfo = (types, property) => {
  if (types[property] === 'duration') {
    return {
      durationUnit: 'seconds'
    };
  }
  if (typeof types[property] === 'function') {
    return {
      options: types[property]()
    };
  } else if (typeof types[property] === 'object') {
    return {
      options: createOptions(types[property])
    };
  }
  if (metricsToInverse.includes(property)) {
    return {
      inverseDeltas: true
    };
  }
  if (hiddenFilterProperties.includes(property)) {
    return {
      hidden: true
    };
  }
  return {};
};

/**
 * Create properties from list of definitions and translator
 *
 * @param {object[]} objects List of property definitions
 * @param {function} translate Property label translator
 * @returns {object[]} Properties
 * @private
 */
export const createPropertiesFromUnifiedObjects = (objects, translate) => objects.reduce((memo, types) => {
  return [...memo, ...Object.keys(types).map(property => Object.assign({
    name: property,
    label: translate(property),
    type: getPropertyType(types[property])
  }, getAdditionalPropertyInfo(types, property)))];
}, []);

/**
 * Create current properties interface from data type
 *
 * @param {string} dataType Data type
 * @returns {object} Properties interface
 */
export const createUnifiedProperties = dataType => {
  const getPropertyGroups = () => load(dataType).then(module => module.getUnifiedSpecForConfig({
    dataType
  })).then(({
    breakdowns = {},
    filters = {},
    metrics = {},
    metadata = {},
    calculated = {}
  }) => {
    const key = createUnifiedTranslationKey(dataType);
    const specProperties = createPropertiesFromUnifiedObjects([breakdowns, metrics, filters, metadata, calculated], prefix(`reporting-data.properties.${key}`));
    const peopleProperties = createPropertiesFromUnifiedObjects([peopleSpec], prefix(`reporting-data.properties.unified.person`));
    return Promise.resolve(fromJS([{
      name: 'unifiedAnalyticsInfo',
      displayName: I18n.text('reporting-data.groups.unified.group'),
      displayOrder: 0,
      hubspotDefined: true,
      properties: [...specProperties, ...peopleProperties]
    }]));
  });
  return {
    getPropertyGroups,
    getProperties: createPropertiesGetterFromGroups(getPropertyGroups)
  };
};

/**
 * Testable internals
 *
 * @constant {object}
 */
export const __TESTABLE__ = {
  createTranslateKey: createUnifiedTranslationKey,
  createProperties: createPropertiesFromUnifiedObjects
};