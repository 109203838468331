import enviro from 'enviro';
import { getAppUrl } from '../utils/getAppUrl';
export const embedTypes = {
  AUDIO: 'audio',
  DIV: 'div',
  EMBED: 'embed',
  IFRAME: 'iframe',
  OBJECT: 'object',
  VIDEO: 'video'
};
export const EmbedFormats = {
  URL: 'url',
  EMBED: 'embed',
  GENERIC_EMBED: 'genericEmbed'
};
export const EmbedSizes = {
  CUSTOM: 'customWidth',
  FULL: 'fullWidth'
};

// TODO: Update the link to the KB article containing new information about using oEmbed
export const EmbedReadMoreLink = 'https://knowledge.hubspot.com/articles/kcs_article/cos-pages-editor/how-can-i-add-a-video-on-my-hubspot-cos-page';
export const ignoreTags = ['a', 'img'];
export const queryParamsDefaults = {
  vimeo: {
    title: '0',
    byline: '0',
    portrait: '0',
    color: '8dc7dc'
  }
};
export const nodeTypes = {
  ELEMENT: 1,
  TEXT: 3
};
export const selfClosingTags = ['area', 'base', 'br', 'col', 'command', 'embed', 'hr', 'img', 'input', 'keygen', 'link', 'meta', 'param', 'source', 'track', 'wbr'];
export let supportedMimeTypes;
(function (supportedMimeTypes) {
  supportedMimeTypes["mp3"] = "audio/mpeg";
  supportedMimeTypes["mp4"] = "video/mp4";
  supportedMimeTypes["ogg"] = "video/ogg";
  supportedMimeTypes["swf"] = "application/x-shockwave-flash";
  supportedMimeTypes["wav"] = "audio/wav";
  supportedMimeTypes["webm"] = "video/webm";
})(supportedMimeTypes || (supportedMimeTypes = {}));
export const supportedScriptPatterns = {
  wistiaInlineResponsive: new RegExp(/<script src="(?:http(?:s)?:)?\/\/fast\.wistia\.com\/embed\/medias\/(.*?)\.jsonp" async[\s\S]*?><\/script>[\s]*?<script src="(?:http(?:s)?:)?\/\/fast\.wistia\.com\/assets\/external\/E-v1\.js" async[\s\S]*?><\/script>[\s]*?<div class="wistia_responsive_padding"[\s\S]*?">[\s]*?<div class="wistia_embed.*?>[\s\S]*?<\/div>[\s]*?<\/div>[\s]*?<\/div>/i),
  wistiaInlineFixed: new RegExp(/<script src="(?:http(?:s)?:)?\/\/fast.wistia.com\/embed\/medias\/(.*?).jsonp" async[\s\S]*?><\/script>[\s]*?<script src="(?:http(?:s)?:)?\/\/fast.wistia.com\/assets\/external\/E-v1.js" async[\s\S]*?><\/script>[\s]*?<div class="wistia_embed.*?" style="(.*?)">[\s\S]*?<\/div>[\s]*?/i),
  wistiaPopoverTextLink: new RegExp(/<script src="(?:http(?:s)?:)?\/\/fast.wistia.com\/embed\/medias\/(.*?).jsonp" async[\s\S]*?><\/script><script src="(?:http(?:s)?:)?\/\/fast.wistia.com\/assets\/external\/E-v1.js" async[s\S]*?><span class="wistia_embed [\s\S]*?popover=true[\s\S]*?<a href="#">([.\s\S]*?)<\/a><\/span>/i),
  wistiaPopoverThumbnail: new RegExp(/<script src="(?:http(?:s)?:)?\/\/fast.wistia.com\/embed\/medias\/(.*?).jsonp" async[\s\S]*?><\/script><script src="(?:http(?:s)?:)?\/\/fast.wistia.com\/assets\/external\/E-v1.js" async[s\S]*?>(?:<div class="wistia_responsive_padding"[\s\S]*?><div class="wistia_responsive_wrapper"[\s\S]*?>)?<span class="wistia_embed [\s\S]*?popover=true[\s\S]*?style="(.*?)">[\s\S]*?<\/span>(?:<\/div><\/div>)?/i),
  embedlySeo: new RegExp(/<blockquote.*?class="embedly-card".*><a\W+href="(.*)">(.*)<\/a>.*<p>(.*)<\/p><\/blockquote>\n*<script.*src="(?:http(?:s)?:)?\/\/cdn\.embedly\.com\/widgets\/platform.js".*><\/script>/i),
  embedlyPlain: new RegExp(/<a\W+hubspot-new-embed\W.*class="embedly-card".*href="(.+?)".*>(.*)<\/a>\n*\W*<script.*cdn.embedly.com.*<\/script>/i),
  scriptEmbed: new RegExp(/{{\s*script_embed\((.*?)\)\s?}}/i)
};
export const lazyLoadingRegex = /<iframe(?:[^>]*( loading="([^ ]+)"){1}[^>]*)+>/;
export const ResponsiveElligibleEmbeds = ['dailymotion', 'giphy', 'google-maps', 'slideshare', 'twentythree', 'vimeo', 'wistia', 'youtube'];
export const urlPatterns = [{
  regex: /youtu\.be\/([\w\-.]+)/i,
  type: 'iframe',
  w: 560,
  h: 315,
  url: '//www.youtube.com/embed/$1',
  allowFullscreen: true,
  service: 'youtube'
}, {
  regex: /youtube\.com(.+)v=([^&]+)/i,
  type: 'iframe',
  w: 560,
  h: 315,
  url: '//www.youtube.com/embed/$2',
  allowFullscreen: true,
  service: 'youtube'
}, {
  //   Add youtube playlist embed
  regex: /youtube.com\/embed\/videoseries?(.*)list=([^&]+)/i,
  type: 'iframe',
  w: 560,
  h: 315,
  url: '//www.youtube.com/embed/videoseries?list=$2',
  allowFullscreen: true,
  service: 'youtube'
}, {
  //   Add ?, &, ; and = characters for capturing query params (e.g ?rel=0)
  regex: /youtube.com\/embed\/([a-z0-9\-_?&;=]+)/i,
  type: 'iframe',
  w: 560,
  h: 315,
  url: '//www.youtube.com/embed/$1',
  allowFullscreen: true,
  service: 'youtube'
}, {
  regex: /vimeo\.com\/([0-9]+[a-z0-9\-_?&;=]*)/i,
  type: 'iframe',
  w: 640,
  h: 360,
  url: '//player.vimeo.com/video/$1',
  allowFullscreen: true,
  service: 'vimeo'
}, {
  regex: /vimeo\.com\/(.*)\/([0-9]+[a-z0-9\-_?&;=]*)/i,
  type: 'iframe',
  w: 640,
  h: 360,
  url: '//player.vimeo.com/video/$2',
  allowFullscreen: true,
  service: 'vimeo'
}, {
  regex: /instagram.com\/p\/([^/]+)/i,
  type: 'iframe',
  w: 425,
  h: 505,
  url: '//instagram.com/p/$1/embed/',
  allowFullscreen: '',
  service: 'instagram'
}, {
  regex: /wistia\.(com|net)\/medias\/([^/]+)/i,
  type: 'iframe',
  w: 620,
  h: 349,
  url: '//fast.wistia.net/embed/iframe/$2',
  service: 'wistia'
}, {
  regex: /maps\.google\.([a-z]{2,3})\/maps\/(.+)msid=(.+)/i,
  type: 'iframe',
  w: 425,
  h: 350,
  url: '//maps.google.com/maps/ms?msid=$2&output=embed"',
  allowFullscreen: '',
  service: 'google-maps'
}, {
  regex: /dai\.ly\/([\w\-.]+)/i,
  type: 'iframe',
  w: 480,
  h: 270,
  url: '//www.dailymotion.com/embed/video/$1',
  allowFullscreen: true,
  service: 'dailymotion'
}, {
  regex: /dailymotion\.com\/video\/([^_]+)/i,
  type: 'iframe',
  w: 480,
  h: 270,
  url: '//www.dailymotion.com/embed/video/$1',
  allowFullscreen: true,
  service: 'dailymotion'
}, {
  regex: /\/\/(\S+)\/((?:\d+|v)\.ihtml\/player\.html)([^/]+)/i,
  type: 'iframe',
  w: 560,
  h: 315,
  url: '//$1/$2$3',
  allowFullscreen: true,
  service: 'twentythree'
}, {
  regex: /giphy\.com\/(gifs|embed|media)\/([^/]+)/i,
  type: 'iframe',
  w: 480,
  h: 460,
  url: '//giphy.com/embed/$2',
  allowFullscreen: true,
  service: 'giphy'
}];
export const LoadingStatus = {
  NONE: null,
  LOADING: 'loading',
  COMPLETE: 'complete'
};
export const ErrorTypes = {
  NONE: null,
  FETCH: 'fetch',
  EMBED_CODE: 'embedCode',
  GENERAL: 'general'
};

// [content-embed-ui] is deployed at `preview.hubspoteditor(qa).com`
export const getContentEmbedUIOrigin = () => {
  // if you want to run [content-embed-ui] locally since, set localStorage.CONTENT_EMBED_ENV = "local"
  // benderproxy hosts all local apps on `local.hubspot(qa).com`. Be careful
  // when running [content-embed-ui] locally as the EmbedIframe has `sandbox`
  // of "allow-scripts allow-same-origin" which exposes possible XSS attacks.
  if (enviro.getShort('content_embed') === 'local') {
    return getAppUrl('local');
  }
  /* eslint-disable-next-line hubspot-dev/no-hublet-references */
  return getAppUrl('preview', 'hubspoteditor', 'na1');
};
export const CONTENT_EMBED_UI_BASE_URL = `${getContentEmbedUIOrigin()}/content-embed-ui`;
export const getContentEmbedUIBaseURL = () => `${getContentEmbedUIOrigin()}/content-embed-ui`;

// These actions must match those in
// https://git.hubteam.com/HubSpot/content-embed-ui/static/js/utils/constants.js
const actionPrefix = 'ContentEmbedUI';
export const ContentEmbedUIEvents = {
  READY: `${actionPrefix}:Ready`,
  SIZE_CHANGE: `${actionPrefix}:SizeChange`,
  SET_CONTENT: `${actionPrefix}:SetContent`,
  SET_CONTENT_DONE: `${actionPrefix}:SetContentDone`,
  DISCONNECT: `${actionPrefix}:Disconnect`
};
export const isDebug = () => enviro.debug('CONTENT_EMBED_UI');
export const logDebug = (...args) => {
  if (isDebug()) {
    console.log('[embed-live-preview]', ...args);
  }
};