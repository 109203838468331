import { usePlatformDashboardSelector } from 'platform-dashboard-ui/store';
import { isSnowflake, isConfigReport
// @ts-expect-error migrate upstream types
} from 'reporting-action-components/lib/report-logic';
import { RequestState } from 'platform-dashboard-ui/filter/requests/request-state';
import { selectDataSourcesProperties, selectDataSourcesPropertiesStatus } from 'platform-dashboard-ui/ducks/dataSourcePropertiesSlice';
import { getOverriddenSnowflakeReportAndFilters } from './getOverriddenSnowflakeReportAndFilters';
import { getOverriddenConfigReportAndFilters, shouldSkipDynamicDateRangeProperty } from './getOverriddenConfigReportAndFilters';
import { getFilterableReportDataSources } from './dataSourceHelpers';
import { isListMembershipProperty } from 'platform-dashboard-ui/filter/utils/listMembershipFilterUtils';
export const doesFilterApplyToReport = ({
  dashboardFilter,
  report
}) => {
  const _isConfigReport = isConfigReport(report);
  if (_isConfigReport) {
    // For "Date range" dynamic quick filter group properties, only override
    // the report's date property if the properties match. Otherwise, skip
    // applying the property to the report (to mimic the legacy experience)
    if (shouldSkipDynamicDateRangeProperty({
      dashboardFilter,
      report
    })) {
      return false;
    }
  } else {
    const {
      propertyScripted,
      propertyName
    } = dashboardFilter;
    // "scripted" properties are FE reporting defined properties, and are only applicable to config based reports.
    // not properly being parsed as a boolean unfortunately
    // List membership filters are an exception to this rule - we do want to apply these to Snowflake reports.
    if (propertyScripted && !isListMembershipProperty(propertyName)) {
      return false;
    }
  }
  const reportDataSources = getFilterableReportDataSources(report);
  return reportDataSources.includes(dashboardFilter.dataSource);
};
export const getApplicableDashboardFilters = ({
  dashboardFilters,
  report
}) => {
  return dashboardFilters.filter(dashboardFilter => {
    return doesFilterApplyToReport({
      dashboardFilter,
      report
    });
  });
};
export const applyDashboardFilters = ({
  dashboardFilters,
  dataSourcesProperties,
  report,
  gates,
  frequency,
  frequencyUseFiscalYear
}) => {
  const overriddenFilters = new Set();
  const applicableDashboardFilters = getApplicableDashboardFilters({
    dashboardFilters,
    report
  });
  if (applicableDashboardFilters.length === 0 && !frequency && !frequencyUseFiscalYear) {
    return {
      overriddenFilters,
      overriddenReport: report
    };
  }

  //Snowflake reports - use object seg data format
  if (isSnowflake(report)) {
    return getOverriddenSnowflakeReportAndFilters({
      dashboardFilters: applicableDashboardFilters,
      dataSourcesProperties,
      report,
      gates,
      frequency,
      frequencyUseFiscalYear
    });
  }

  // Single object (config) reports - use contact search data format
  if (isConfigReport(report)) {
    return getOverriddenConfigReportAndFilters({
      dashboardFilters: applicableDashboardFilters,
      dataSourcesProperties,
      report,
      frequency,
      frequencyUseFiscalYear
    });
  }
  return {
    overriddenFilters,
    overriddenReport: report
  };
};
export const useOverriddenReportAndFilters = ({
  dashboardFilters,
  report,
  frequency,
  frequencyUseFiscalYear,
  gates
}) => {
  const dataSourceKeys = dashboardFilters.map(filter => filter.dataSource);
  const dataSourcesProperties = usePlatformDashboardSelector(selectDataSourcesProperties(dataSourceKeys));
  const dataSourcesPropertiesStatus = usePlatformDashboardSelector(selectDataSourcesPropertiesStatus(dataSourceKeys));
  if (dataSourcesPropertiesStatus === RequestState.SUCCEEDED) {
    const appliedDashFiltersObj = applyDashboardFilters({
      dashboardFilters,
      dataSourcesProperties,
      report,
      frequency,
      frequencyUseFiscalYear,
      gates
    });
    return {
      overriddenReport: appliedDashFiltersObj.overriddenReport,
      overriddenFilters: Array.from(appliedDashFiltersObj.overriddenFilters)
    };
  } else {
    return {
      overriddenReport: null,
      overriddenFilters: []
    };
  }
};