'use es6';

import I18n from 'I18n';
import isValidI18nKey from 'I18n/utils/isValidI18nKey';
import { List } from 'immutable';
import { CALL, DEALS, EMAIL, LEADS, MEETING_EVENT, SEQUENCE_ENROLLMENTS, TASK } from 'reporting-data/constants/dataTypes';
import getMetricLabel from 'reporting-data/public/get-metric-label';
import { toMetricKey } from 'reporting-data/v2/dataset/datasetMetrics';
import { getDataTypeName, isUnifiedAnalyticsReport } from 'sales-analytics-lib/utils/salesAnalyticsHelpers';
import { ADS_METRICS, BROWSERS_METRICS, CALL_METRICS, DEVICE_TYPES_METRICS, GEOLOCATION_METRICS, LEAD_CAPTURE_METRICS, LEAD_METRICS, MARKETING_EMAIL_METRICS, MEETING_METRICS, PAGES_METRICS, SALES_VELOCITY_METRICS, SEQUENCE_ENROLLMENT_METRICS, SOURCES_METRICS, TASK_METRICS, UTM_PARAM_METRICS } from './inDevReportMetricEditorSchemas';
import { SUB_APPS } from 'reporting-data/tsTypes/subAppTypes';
const METRIC_KEY_DELIMITER = '|';
const UNIFIED_ANALYTICS_DATA_TYPE_ALIAS = 'UNIFIED_ANALYTICS';
const subAppDataTypeTableOnlyMetrics = {
  [SUB_APPS.pages]: {
    [UNIFIED_ANALYTICS_DATA_TYPE_ALIAS]: ['COUNT|CREATED_TIMESTAMP', 'COUNT|PUBLISHED_TIMESTAMP', 'COUNT|PUBLISH_STATUS_ID', 'COUNT|DOMAIN_ID', 'COUNT|CONTENT_GROUP_ID', 'COUNT|BLOG_AUTHOR_ID', 'COUNT|LANGUAGE', 'COUNT|URL', 'COUNT|HTML_TITLE', 'COUNT|CONTENT_TYPE_CODE']
  },
  [SUB_APPS.leadCapture]: {
    [UNIFIED_ANALYTICS_DATA_TYPE_ALIAS]: ['COUNT|TYPE_ID']
  }
};
export const subAppDataTypeMetrics = {
  [SUB_APPS.prospectEngagement]: {
    [CALL]: CALL_METRICS,
    [MEETING_EVENT]: MEETING_METRICS,
    [SEQUENCE_ENROLLMENTS]: SEQUENCE_ENROLLMENT_METRICS,
    [TASK]: TASK_METRICS
  },
  [SUB_APPS.leadFunnel]: {
    [LEADS]: LEAD_METRICS
  },
  [SUB_APPS.salesVelocity]: {
    [DEALS]: SALES_VELOCITY_METRICS
  },
  [SUB_APPS.ads]: {
    [UNIFIED_ANALYTICS_DATA_TYPE_ALIAS]: ADS_METRICS
  },
  [SUB_APPS.leadCapture]: {
    [UNIFIED_ANALYTICS_DATA_TYPE_ALIAS]: LEAD_CAPTURE_METRICS
  },
  [SUB_APPS.marketingEmails]: {
    [EMAIL]: MARKETING_EMAIL_METRICS
  },
  [SUB_APPS.sources]: {
    [UNIFIED_ANALYTICS_DATA_TYPE_ALIAS]: SOURCES_METRICS
  },
  [SUB_APPS.pages]: {
    [UNIFIED_ANALYTICS_DATA_TYPE_ALIAS]: PAGES_METRICS
  },
  [SUB_APPS.utmParameters]: {
    [UNIFIED_ANALYTICS_DATA_TYPE_ALIAS]: UTM_PARAM_METRICS
  },
  [SUB_APPS.deviceTypes]: {
    [UNIFIED_ANALYTICS_DATA_TYPE_ALIAS]: DEVICE_TYPES_METRICS
  },
  [SUB_APPS.geolocation]: {
    [UNIFIED_ANALYTICS_DATA_TYPE_ALIAS]: GEOLOCATION_METRICS
  },
  [SUB_APPS.browsers]: {
    [UNIFIED_ANALYTICS_DATA_TYPE_ALIAS]: BROWSERS_METRICS
  }
};
export const getMetricsForSubAppDataType = (subAppKey, dataType, options = {}) => {
  const {
    getTableOnlyMetrics
  } = options;
  const metricMap = getTableOnlyMetrics ? subAppDataTypeTableOnlyMetrics : subAppDataTypeMetrics;
  const subAppMetrics = metricMap[subAppKey] || {};
  return subAppMetrics[dataType] || [];
};
export const getMetricKey = (property, metricType) => `${metricType}${METRIC_KEY_DELIMITER}${property}`;
export const getMetricFieldsFromKey = metricKey => {
  const splitKey = metricKey.split(METRIC_KEY_DELIMITER);
  return {
    metricTypes: [splitKey[0]],
    property: splitKey[1]
  };
};
export const getMetricOptionKeys = (report, subAppKey, options = {}) => {
  const dataType = isUnifiedAnalyticsReport(report) ? UNIFIED_ANALYTICS_DATA_TYPE_ALIAS : getDataTypeName(report);
  return getMetricsForSubAppDataType(subAppKey, dataType, options);
};
export const getMetricOptions = (report, subAppKey, options = {}) => {
  const dataType = isUnifiedAnalyticsReport(report) ? UNIFIED_ANALYTICS_DATA_TYPE_ALIAS : getDataTypeName(report);
  const metricKeys = getMetricsForSubAppDataType(subAppKey, dataType, options);
  const metricOptions = metricKeys.map(metricKey => {
    const {
      property,
      metricTypes: [metricType]
    } = getMetricFieldsFromKey(metricKey);
    const propertyLabel = I18n.text(`sales-analytics-lib.metricSelect.options.${dataType}.${property}`);
    return {
      text: getMetricLabel(propertyLabel, metricType, property),
      value: metricKey
    };
  });
  return metricOptions;
};
export const getTableOnlyMetricOptionKeys = (report, subAppKey) => getMetricOptionKeys(report, subAppKey, {
  getTableOnlyMetrics: true
});
export const getTableOnlyMetricOptions = (report, subAppKey) => getMetricOptions(report, subAppKey, {
  getTableOnlyMetrics: true
});
export const getMetricTooltip = (dataType, metric) => {
  const {
    property,
    metricTypes: [metricType]
  } = metric;
  const prefix = `reporting-data.properties.crm-object.tooltips.${dataType}`;
  const withoutMetricType = `${prefix}.${property}`;
  const withMetricType = `${withoutMetricType}_${metricType}`;
  if (isValidI18nKey(withMetricType)) {
    return withMetricType;
  } else if (isValidI18nKey(withoutMetricType)) {
    return withoutMetricType;
  }
  return null;
};
export const getReportTemplateTableMetrics = report => {
  const metrics = report.getIn(['config', 'metrics']) || List();
  return metrics.map(metricObject => {
    const property = metricObject.get('property');
    const metricType = metricObject.get('metricTypes').first();
    return toMetricKey({
      property,
      type: metricType
    });
  });
};