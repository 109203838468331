module.exports = {
  "clickCreateAsset": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "create"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "create"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report",
          "dashboard"
        ],
        "isOptional": false
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickGoToDashboard": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "navigate"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "go-to-dashboard"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report",
          "dashboard"
        ],
        "isOptional": false
      },
      "destination": {
        "type": [
          "existing-dashboard"
        ],
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "saveNewAssetInteraction": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "create"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "save-new"
        ],
        "isOptional": false
      },
      "step": {
        "type": [
          "start",
          "exit"
        ],
        "isOptional": false
      },
      "whichFlow": {
        "type": [
          "save-from-template",
          "save-from-scratch",
          "save-from-drilldown",
          "save-updates-as-new",
          "clone",
          "ai-builder-generated-report",
          "save-from-index",
          "save-from-crm-ai-insights"
        ],
        "isOptional": false
      },
      "whichStage": {
        "type": [
          "report-name-and-destination",
          "assignment-mode",
          "specific-partitioning"
        ],
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "saveNewAsset": {
    "class": "usage",
    "properties": {
      "actionType": {
        "type": [
          "create"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "save-new"
        ],
        "isOptional": false
      },
      "step": {
        "type": [
          "complete"
        ],
        "isOptional": false
      },
      "whichFlow": {
        "type": [
          "save-from-template",
          "save-from-scratch",
          "save-from-drilldown",
          "save-updates-as-new",
          "clone",
          "report-destination",
          "ai-builder-generated-report",
          "save-from-index",
          "save-from-crm-ai-insights"
        ],
        "isOptional": false
      },
      "destination": {
        "type": [
          "new-dashboard",
          "existing-dashboard",
          "none"
        ],
        "isOptional": true
      },
      "assignmentMode": {
        "type": [
          "everyone",
          "specific",
          "private"
        ],
        "isOptional": true
      },
      "permissionValue": {
        "type": [
          "none",
          "view",
          "edit"
        ],
        "isOptional": true
      },
      "teamCount": {
        "type": "number",
        "isOptional": true
      },
      "userCount": {
        "type": "number",
        "isOptional": true
      },
      "withGeneratedDescription": {
        "type": "boolean",
        "isOptional": true
      },
      "wasGeneratedDescriptionCustomized": {
        "type": "boolean",
        "isOptional": true
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickSaveAssetSuccessLink": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "navigate"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "go-to-saved-asset"
        ],
        "isOptional": false
      },
      "whichFlow": {
        "type": [
          "save-from-template",
          "save-from-scratch",
          "save-from-drilldown",
          "save-updates-as-new",
          "clone",
          "ai-builder-generated-report",
          "save-from-index",
          "save-from-crm-ai-insights"
        ],
        "isOptional": false
      },
      "destination": {
        "type": [
          "new-dashboard",
          "existing-dashboard",
          "none"
        ],
        "isOptional": true
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickCreateCustomReport": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "create"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "create",
          "create-from-template"
        ],
        "isOptional": false
      },
      "whichFlow": {
        "type": [
          "save-from-template",
          "save-from-scratch",
          "ai-builder-generated-report",
          "save-from-index",
          "save-from-crm-ai-insights"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "report-insights"
        ],
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "templateKey": {
        "type": "string",
        "isOptional": true
      },
      "step": {
        "type": [
          "customize-template"
        ],
        "isOptional": true
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickRestoreAsset": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "create"
        ]
      },
      "action": {
        "type": [
          "restore"
        ]
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickDeleteAsset": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ]
      },
      "action": {
        "type": [
          "delete"
        ]
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickSetBusinessUnitNew": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "tag"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard",
          "report"
        ],
        "isOptional": false
      },
      "count": {
        "type": "number",
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "single-asset-view",
          "asset-list-page",
          "actions-dropdown"
        ],
        "isOptional": false
      },
      "interactionWith": [
        "asset-action"
      ],
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "setBusinessUnitNew": {
    "name": "ANALYTICS_INTERACTION",
    "class": "usage",
    "properties": {
      "actionType": {
        "type": [
          "save"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "tag"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard",
          "report"
        ],
        "isOptional": false
      },
      "count": {
        "type": "number",
        "isOptional": false
      },
      "interactionWith": [
        "modal"
      ],
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "restoreAsset": {
    "class": "usage",
    "properties": {
      "actionType": {
        "type": [
          "save"
        ]
      },
      "action": {
        "type": [
          "restore"
        ]
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "isAdmin": {
        "isOptional": false,
        "type": "boolean"
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "deleteAsset": {
    "class": "usage",
    "properties": {
      "actionType": {
        "type": [
          "delete"
        ]
      },
      "action": {
        "type": [
          "delete"
        ]
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickShareAsset": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "share"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "email",
          "share-asset",
          "export"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown",
          "widget"
        ],
        "isOptional": false
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "selectedReport": {
        "type": [
          "main-report",
          "summarized-table",
          "unsummarized-table"
        ],
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickCopyReportUrl": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "share"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "copy-report-url"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": false
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickManageRecurringShareAsset": {
    "class": "interaction",
    "properties": {
      "object": {
        "type": [
          "email",
          "share-asset"
        ]
      },
      "actionType": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "open"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown",
          "widget"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "sendReportShareAssetNow": {
    "class": "interaction",
    "properties": {
      "object": {
        "type": [
          "email",
          "slack"
        ]
      },
      "actionType": {
        "type": [
          "share"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "send-email",
          "send-slack"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "dialog"
        ],
        "isOptional": false
      },
      "externalEmailDomains": {
        "type": "string",
        "isOptional": true
      },
      "exportType": {
        "type": "string",
        "isOptional": true
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "createRecurringShareAssets": {
    "class": "interaction",
    "properties": {
      "object": {
        "type": [
          "email",
          "slack"
        ]
      },
      "actionType": {
        "type": [
          "share"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "create-recurring-email",
          "create-recurring-slack"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "dialog"
        ],
        "isOptional": false
      },
      "exportType": {
        "type": "string",
        "isOptional": true
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "startEditRecurringShareAssetFlow": {
    "class": "interaction",
    "properties": {
      "object": {
        "type": [
          "email",
          "slack"
        ]
      },
      "actionType": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "change-owner",
          "delete",
          "edit-schedule"
        ]
      },
      "interactionSource": {
        "type": [
          "actions-dropdown",
          "dialog"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "updateSavedRecurringShareAsset": {
    "class": "usage",
    "properties": {
      "object": {
        "type": [
          "email",
          "slack"
        ]
      },
      "actionType": {
        "type": [
          "save"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "change-owner",
          "delete",
          "edit-schedule"
        ]
      },
      "interactionSource": {
        "type": [
          "actions-dropdown",
          "dialog"
        ],
        "isOptional": false
      },
      "exportType": {
        "type": "string",
        "isOptional": true
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickPreviewShareAsset": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "view"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "open",
          "export"
        ],
        "isOptional": false
      },
      "object": {
        "type": [
          "email",
          "slack",
          "report"
        ]
      },
      "interactionSource": {
        "type": [
          "dialog",
          "actions-dropdown",
          "widget"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickEditInBuilder": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "edit-in-builder"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickGoToCrm": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "description"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "go-to-crm"
        ],
        "isOptional": false
      },
      "dataType": {
        "type": "string"
      },
      "interactionSource": {
        "type": [
          "reports-list",
          "dashboard"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickGoToDatasets": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "navigate"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "go-to-datasets"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickUpdateAsset": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "edit",
          "change-owner",
          "rename",
          "editDescription"
        ],
        "isOptional": false
      },
      "object": {
        "type": [
          "name",
          "owner",
          "business unit",
          "config",
          "filters",
          "description"
        ]
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "completeAssetUpdate": {
    "class": "usage",
    "properties": {
      "actionType": {
        "type": [
          "save"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "edit",
          "change-owner",
          "rename"
        ],
        "isOptional": false
      },
      "object": {
        "type": [
          "name",
          "owner",
          "description",
          "business unit",
          "config",
          "filters",
          "layout"
        ]
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "withGeneratedDescription": {
        "type": "boolean",
        "isOptional": true
      },
      "wasGeneratedDescriptionCustomized": {
        "type": "boolean",
        "isOptional": true
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "cancelAssetUpdate": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "close"
        ],
        "isOptional": false
      },
      "object": {
        "type": [
          "name",
          "owner",
          "description",
          "business unit",
          "config",
          "filters"
        ]
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickGenerateReportDescription": {
    "class": "interaction",
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1",
    "properties": {
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "clickRateGeneratedReportDescription": {
    "class": "interaction",
    "properties": {
      "rating": {
        "type": [
          "THUMBS_UP",
          "THUMBS_DOWN"
        ]
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "editAssetAccessInteraction": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "edit-access"
        ],
        "isOptional": false
      },
      "step": {
        "type": [
          "start",
          "exit"
        ],
        "isOptional": false
      },
      "count": {
        "type": "number",
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "completeEditAssetAccess": {
    "class": "usage",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "edit-access"
        ],
        "isOptional": false
      },
      "step": {
        "type": [
          "complete"
        ],
        "isOptional": false
      },
      "assignmentMode": {
        "type": [
          "everyone",
          "specific",
          "private"
        ],
        "isOptional": true
      },
      "permissionValue": {
        "type": [
          "none",
          "view",
          "edit"
        ],
        "isOptional": true
      },
      "teamCount": {
        "type": "number",
        "isOptional": true
      },
      "userCount": {
        "type": "number",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickRefreshAsset": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "view"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "refresh"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "report-viewer",
          "widget"
        ],
        "isOptional": false
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "openAsset": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "view"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "open"
        ],
        "isOptional": false
      },
      "aiInsightsAvailable": {
        "type": "boolean",
        "isOptional": true
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "addToDashboardInteraction": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "add-to-dashboard"
        ],
        "isOptional": false
      },
      "step": {
        "type": [
          "start",
          "exit"
        ],
        "isOptional": false
      },
      "count": {
        "type": "number",
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "completeAddToDashboard": {
    "class": "usage",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "add-to-dashboard"
        ],
        "isOptional": false
      },
      "step": {
        "type": [
          "complete"
        ],
        "isOptional": false
      },
      "count": {
        "type": "number",
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickDeleteActionNew": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "version": "1",
    "properties": {
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "clickSetDefaultDashboard": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ]
      },
      "action": {
        "type": [
          "delete"
        ]
      },
      "step": [
        "start"
      ],
      "assetType": [
        "dashboard"
      ],
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "deleteDashboardNew": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "subscreen": [
        "dashboard",
        "dashboard-manager"
      ],
      "action": {
        "type": [
          "edit-default-dashboard"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "dashboard"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "setDefaultDashboardNew": {
    "class": "interaction",
    "properties": {
      "subscreen": [
        "dashboard",
        "dashboard-manager"
      ],
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "removeDefaultDashboardNew": {
    "class": "interaction",
    "properties": {
      "subscreen": [
        "dashboard",
        "dashboard-manager"
      ],
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "updateFavoriteAssetsNew": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "save",
          "create",
          "edit"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard",
          "report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "clickMoveReportNew": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "move-report-to-dashboard"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "dashboard"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "onConfirmMoveReport": {
    "class": "interaction",
    "properties": {
      "use": {
        "type": "string",
        "isOptional": true
      },
      "dashboardType": {
        "type": [
          "NEW",
          "EXISTING"
        ]
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "goToCrm": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "navigate"
        ]
      },
      "action": {
        "type": [
          "go-to-crm"
        ]
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickAccountSettingExternalEmailToolTipLink": {
    "class": "interaction",
    "properties": {
      "actionType": [
        "click"
      ],
      "action": {
        "type": [
          "Navigate-to-account-settings-page",
          "Navigate-to-learn-more-about-connecting-verified-domains"
        ]
      },
      "assetType": {
        "type": [
          "dashboard",
          "report"
        ]
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "createReportInBuilder": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "create"
        ]
      },
      "action": {
        "type": [
          "create-report"
        ]
      },
      "interactionSource": {
        "type": "string",
        "isOptional": false
      },
      "objectType": {
        "type": "string",
        "isOptional": false
      },
      "objectTypeId": {
        "type": "string",
        "isOptional": true
      },
      "eventName": {
        "type": [
          "createReportInBuilder"
        ]
      },
      "whichBuilder": {
        "type": [
          "SORB",
          "CRB"
        ]
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  }
};