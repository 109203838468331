import { SUB_APPS } from 'reporting-data/tsTypes/subAppTypes';
import { PROSPECTING_WORKSPACE_ACCESS } from '../constants/scopes';
import { LEAD_OBJECT_GATE, EMAIL_REPORT_GATE } from '../constants/gates';
const REPORT_COLLECTIONS_SUB_APP_DATA = {
  [SUB_APPS.prospectEngagement]: {
    sequences: {
      reports: ['sequencesOverview', 'uniqueContactsSequenced', 'sequenceActionsByRep']
    },
    meetings: {
      reports: ['meetingsOverview', 'meetingsByRepChart', 'meetingsByRepTable']
    },
    calls: {
      reports: ['callsOverview', 'callsByRepChart', 'callsByRepTable']
    },
    tasks: {
      reports: ['tasksOverview', 'tasksByRepChart', 'tasksByRepTable']
    },
    emails: {
      reports: ['emailsOverview', 'emailsByRepChart', 'emailsByRepTable'],
      gate: EMAIL_REPORT_GATE
    }
  },
  [SUB_APPS.leadFunnel]: {
    leads: {
      reports: ['leadWorkRateOverview', 'leadWorkRateChart', 'leadWorkRateTable'],
      scope: PROSPECTING_WORKSPACE_ACCESS,
      gate: LEAD_OBJECT_GATE
    },
    leadStages: {
      reports: ['leadJourney', 'leadJourneyTable']
    }
  },
  [SUB_APPS.salesVelocity]: {
    salesVelocity: {
      reports: ['salesVelocityOverview', 'salesVelocityChart', 'salesVelocityTable']
    }
  }
};
export const getSubAppData = subAppKey => REPORT_COLLECTIONS_SUB_APP_DATA[subAppKey];
export const isSubAppReportCollection = subAppKey => !!getSubAppData(subAppKey);
export const getSubAppTabs = (subAppKey, scopes, gates) => {
  const subAppData = getSubAppData(subAppKey) || {};
  return Object.keys(subAppData).filter(tabKey => {
    const tabData = subAppData[tabKey];
    const requiredScope = tabData.scope;
    const requiredGate = tabData.gate;
    return (!requiredScope || scopes.includes(requiredScope)) && (!requiredGate || gates.includes(requiredGate));
  });
};
export const getReportsForTab = (subAppKey, tab) => {
  const tabData = getSubAppData(subAppKey)[tab];
  return tabData ? tabData.reports : [];
};