import { createSlice } from '@reduxjs/toolkit';
import { checkIfPropertyExists, getPropertyObj, bulkUpdateFiltersOnAddFilter, bulkUpdateFiltersOnRemoveFilter, updateFilterOnRemoveFilterByName, updateFiltersOnAddFilter, updateFiltersOnRemoveFilter, updateFiltersOnUpdateFilter } from './utils';
import { savedDashboardFiltersActions } from '../savedDashboardFilters/slice';
import { areFiltersDataSourceAndPropertyNameSame } from '../dashboardFilters/types';
import { getDefaultFilterSet, getFilterSetById } from '../../filter/utils/savedDashboardFilters';
export const appliedDashboardFiltersNamespace = 'appliedDashboardFilters';
const initialState = {
  isPanelOpen: false,
  filters: {},
  frequency: {}
};
const appliedDashboardFiltersSlice = createSlice({
  name: appliedDashboardFiltersNamespace,
  initialState,
  reducers: {
    initializeDashboardFilters: (state, action) => {
      const {
        payload: {
          dashboardId,
          filters: currentAdHocFilters = [],
          filterSetId,
          filterSets = [],
          frequency: adhocFrequency,
          useFiscalYear: adhocUseFiscalYear
        }
      } = action;
      const maybeDefaultOverrideFilterSet = filterSetId ? getFilterSetById(filterSets, filterSetId) : getDefaultFilterSet(filterSets);
      const appliedFilterSetFiltersLessAdhocOverrides = (maybeDefaultOverrideFilterSet ? maybeDefaultOverrideFilterSet.filters : []).filter(savedFilter => !currentAdHocFilters.some(adhocFilter => areFiltersDataSourceAndPropertyNameSame(savedFilter, adhocFilter)));

      // Merges adhoc and saved filters together with adhoc filters overwriting similar saved filters
      const nextAppliedFilters = [appliedFilterSetFiltersLessAdhocOverrides, currentAdHocFilters].flat();
      state.filters[dashboardId] = nextAppliedFilters;

      // if adhoc frequency available use that otherwise use from applied filter set
      const nextFrequency = adhocFrequency || (maybeDefaultOverrideFilterSet ? maybeDefaultOverrideFilterSet.frequency : undefined);
      const nextUseFiscalYear = adhocUseFiscalYear != null ? adhocUseFiscalYear : maybeDefaultOverrideFilterSet ? maybeDefaultOverrideFilterSet.useFiscalYear : false;
      state.frequency[dashboardId] = {
        frequency: nextFrequency,
        useFiscalYear: nextUseFiscalYear
      };
    },
    addDashboardFilter: (state, action) => {
      const {
        payload: {
          dashboardId,
          filterData
        }
      } = action;
      const prevFilters = state.filters[dashboardId] || [];
      const doesPropertyExistInFilters = checkIfPropertyExists(prevFilters, getPropertyObj(filterData));
      if (!doesPropertyExistInFilters) {
        state.filters[dashboardId] = updateFiltersOnAddFilter(prevFilters, filterData);
      }
    },
    addGroupDashboardFilter: (state, action) => {
      const {
        payload: {
          dashboardId,
          quickFilterGroupOperators,
          systemGenerated,
          quickFilterGroupName
        }
      } = action;
      const prevFilters = state.filters[dashboardId] || [];
      state.filters[dashboardId] = bulkUpdateFiltersOnAddFilter(prevFilters, quickFilterGroupOperators, systemGenerated, quickFilterGroupName);
    },
    updateDashboardFilter: (state, action) => {
      const {
        payload: {
          index,
          dashboardId,
          filterData
        }
      } = action;
      state.filters[dashboardId] = updateFiltersOnUpdateFilter(state.filters[dashboardId] || [], index, filterData);
    },
    removeDashboardFilter: (state, action) => {
      const {
        payload: {
          index,
          dashboardId
        }
      } = action;
      state.filters[dashboardId] = updateFiltersOnRemoveFilter(state.filters[dashboardId] || [], index);
    },
    removeDashboardFilterByName: (state, action) => {
      const {
        payload: {
          dataSource,
          dashboardId,
          propertyName
        }
      } = action;
      state.filters[dashboardId] = updateFilterOnRemoveFilterByName(state.filters[dashboardId] || [], dataSource, propertyName);
    },
    removeGroupDashboardFilter: (state, action) => {
      const {
        payload: {
          dashboardId,
          properties
        }
      } = action;
      state.filters[dashboardId] = bulkUpdateFiltersOnRemoveFilter(state.filters[dashboardId] || [], properties);
    },
    removeCurrentDashboardFilters: (state, action) => {
      const {
        payload: {
          dashboardId
        }
      } = action;
      state.filters[dashboardId] = [];
      state.frequency[dashboardId] = {
        frequency: undefined
      };
    },
    removeAllDashboardFilters: state => {
      state.isPanelOpen = false;
      state.filters = {};
      state.frequency = {};
    },
    openDashboardFiltersPanel: state => {
      state.isPanelOpen = true;
    },
    closeDashboardFiltersPanel: state => {
      state.isPanelOpen = false;
    },
    toggleDashboardFiltersPanel: state => {
      state.isPanelOpen = !state.isPanelOpen;
    },
    updateFrequency: (state, action) => {
      const {
        dashboardId,
        frequency
      } = action.payload;
      const frequencySetting = dashboardId in state.frequency ? state.frequency[dashboardId] : {};
      frequencySetting.frequency = frequency;
      state.frequency[dashboardId] = frequencySetting;
    },
    updateFrequencyUseFiscalYear: (state, action) => {
      const {
        dashboardId,
        useFiscalYear
      } = action.payload;
      const frequencySetting = dashboardId in state.frequency ? state.frequency[dashboardId] : {};
      frequencySetting.useFiscalYear = useFiscalYear;
      state.frequency[dashboardId] = frequencySetting;
    },
    loadMigratedLegacyFilters: (state, action) => {
      const {
        dashboardId,
        frequency,
        dashboardFilters
      } = action.payload;
      if (frequency) {
        const frequencySetting = dashboardId in state.frequency ? state.frequency[dashboardId] : {};
        frequencySetting.frequency = frequency;
        state.frequency[dashboardId] = frequencySetting;
      }
      state.filters[dashboardId] = dashboardFilters;
    }
  },
  extraReducers: builder => {
    builder.addCase(savedDashboardFiltersActions.loadSavedDashboardFilterSet, (state, action) => {
      const {
        payload: {
          dashboardId,
          nextAppliedFilterSet
        }
      } = action;
      const frequencySetting = dashboardId in state.frequency ? state.frequency[dashboardId] : {};
      if (nextAppliedFilterSet) {
        state.filters[dashboardId] = nextAppliedFilterSet.filters;
        if (nextAppliedFilterSet.frequency) {
          frequencySetting.frequency = nextAppliedFilterSet.frequency;
        }
        if ('useFiscalYear' in nextAppliedFilterSet) {
          frequencySetting.useFiscalYear = nextAppliedFilterSet.useFiscalYear;
        }
        state.frequency[dashboardId] = frequencySetting;
      } else {
        state.filters[dashboardId] = [];
      }
    }).addCase(savedDashboardFiltersActions.addNewSavedDashboardFilterSet, (state, action) => {
      const {
        payload: {
          dashboardId,
          filters,
          frequency,
          useFiscalYear
        }
      } = action;
      state.filters[dashboardId] = filters;
      const frequencySetting = dashboardId in state.frequency ? state.frequency[dashboardId] : {};
      if (frequency) {
        frequencySetting.frequency = frequency;
      }
      if (useFiscalYear !== undefined) {
        frequencySetting.useFiscalYear = useFiscalYear;
      }
      state.frequency[dashboardId] = frequencySetting;
    });
  }
});
export const appliedDashboardFiltersActions = appliedDashboardFiltersSlice.actions;
export const appliedDashboardFiltersReducer = appliedDashboardFiltersSlice.reducer;
export const __TESTABLE__ = {
  initialState
};